<template>
  <div v-if="depoimentos!=''">
    <div class="testimonial-area t-bg" style="padding-top: 60px;padding-bottom: 60px;">
      <div class="container ">
        <div class="row">
          <div class="col-xl-12">
            <!-- Section Tittle -->
            <div class="section-tittle section-tittle6 mb-50">
              <div class="front-text">
                <h2 class="">O que dizem nossos clientes!</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-10 col-lg-11 col-md-10 offset-xl-1">

            <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="1"
              :breakpoints="{ 768: { slidesPerView: 1 }, 0: { slidesPerView: 1 } }" class="h1-testimonial-active">
              <!-- Single Testimonial -->
              <SwiperSlide v-for="depoi in depoimentos" :key="depoi.itiId" class="single-testimonial">
                <div class="testimonial-caption ">
                  <div class="testimonial-top-cap">
                    <!-- SVG icon -->
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="86px"
                      height="63px">
                      <path fill-rule="evenodd" stroke-width="1px" stroke="rgb(255, 95, 19)" fill-opacity="0"
                        fill="rgb(0, 0, 0)"
                        d="M82.623,59.861 L48.661,59.861 L48.661,25.988 L59.982,3.406 L76.963,3.406 L65.642,25.988 L82.623,25.988 L82.623,59.861 ZM3.377,25.988 L14.698,3.406 L31.679,3.406 L20.358,25.988 L37.340,25.988 L37.340,59.861 L3.377,59.861 L3.377,25.988 Z" />
                    </svg>
                    <p v-html="depoi.itiResumo1"></p>
                  </div>
                  <!-- founder -->
                  <div class="testimonial-founder d-flex align-items-center">
                    <div class="founder-text">
                      <img v-if="depoi.insInstitucionalImagens != ''"
                        :src="$imgURL + depoi.insInstitucionalImagens[0].itgUrlImagem" alt="user"><br>
                      <span> {{ depoi.itiNome }}</span>
                      <p>{{ depoi.itiReferencia }}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
export default {
  name: 'PageDepoimentos',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    depoimentos:[],  //owl-carousel owl-simple owl-testimonials-photo
  }),
  methods: {

  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/13/depoimentos')
      .then(response => {
        this.depoimentos = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}</style>
