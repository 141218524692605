<template>
  <main>
    <div class="slider-area ">
      <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
        v-if="informacoestopo != '' && informacoestopo[0].insInstitucionalImagens != ''"
        :style="{ backgroundImage: 'url(' + $imgURL + informacoestopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap pt-100">
                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{ informacoestopo[0].itiNome }}</h2>
                <!--nav aria-label="breadcrumb ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Informações Gerais</a></li>
                  </ol>
                </!--nav-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content" style="padding-top: 80px;padding-bottom: 80px;">
      <div class="container">
        <div class="row">
          <aside class="col-lg-3">
            <div class="sidebar">
              <div class="widget widget-cats">
                <h5 class="widget-title" style="text-decoration:underline;">{{ SegmentoSelecionado }}</h5>
                <!-- End .widget-title -->
                <ul v-if="SegmentosMenu != ''">
                  <li style="padding-top: 5px;padding-bottom: 5px;" v-for="itens in SegmentosMenu" :key="itens.sgiId">
                    <a href="#" @click.prevent="carregaTexto(itens.sgiId)"> {{ itens.sgiNomeSegmento }}</a>
                  </li>
                </ul>
              </div><!-- End .widget -->
            </div><!-- End .sidebar sidebar-shop -->
          </aside><!-- End .col-lg-3 -->
          <div class="col-lg-9" v-if="informacoes != ''">
            <article 
                      class="entry single-entry" 
                      v-for="(itensInfo, index) in informacoes" 
                      :key="itensInfo.itiId"
                      :class="{'bg-light-gray': index % 2 !== 0}"
                      style="padding: 20px;"
                  >
                    <div class="entry-body">
                      <h2 class="entry-title pb-3">
                        {{ itensInfo.itiNome }}
                      </h2>

                      <div class="entry-content editor-content" style="padding-bottom: 60px;">
                        <img v-if="itensInfo.insInstitucionalImagens!=''" :src="$imgURL + itensInfo.insInstitucionalImagens[0].itgUrlImagem" style="width: 100%;height: auto;padding-bottom: 30px;" />
                        <p v-html="itensInfo.itiResumo1"></p>
                      </div>
                    </div>
                  </article>
          </div><!-- End .col-lg-9 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
      </div><!-- End .page-content -->
  </main>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'ServicosView',
  setup() {
    const route = useRoute();
    const id = route.params.idServico;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    informacoestopo: [],
    informacoes: [],
    informacao: [],
    SegmentoSelecionado: '',
    Segmentos: [],
    SegmentosMenu: [],
    SegmentosMenuInst:[],
    SegmentosInst: [],
    informacoesGeral:[],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Serviços - Corofergs Soluções em Usinagem'
      })
    },
    carregaTexto(_sgiId){
      this.informacoes = this.informacoesGeral.filter(
                          x => x.insInstitucionalSegmentos &&
                              x.insInstitucionalSegmentos.some(
                                w => w.insSegmentos && w.insSegmentos.sgiId === Number(_sgiId)
                              )
                        );
    }
  },
  created() {
    this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/17/informacoesgeraistopo')
          .then(response => {
            this.informacoestopo = response.data;
          })
          .catch(error => {
            console.error(error);
          });


         axios.get('/Process/InsSegmentos/GetAll')
            .then(response => {
                this.SegmentosInst = response.data;
                this.SegmentosMenuInst = this.SegmentosInst.filter(x => x.sgiVisivel).sort((a, b) => {
                    const nomeA = a.sgiNomeSegmento || "";
                    const nomeB = b.sgiNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });
                if (this.id) {
                     this.SegmentosMenu = this.SegmentosMenuInst.filter(x=> x.sgiIdPai == this.id).sort((a, b) => a.sgiOrdem - b.sgiOrdem);
                     this.SegmentoSelecionado = this.SegmentosMenuInst.filter(x => x.sgiId === Number(this.id))[0].sgiNomeSegmento;
                }
                axios.get('/Process/InsInstitucional/institucional/36/atuacao')
                  .then(response => {
                    this.informacoesGeral = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
                    if (this.id) {
                      this.informacoes = this.informacoesGeral.filter(
                          x => x.insInstitucionalSegmentos &&
                              x.insInstitucionalSegmentos.some(
                                w => w.insSegmentos && w.insSegmentos.sgiIdPai === Number(this.id)
                              )
                        );
                        this.carregaTexto(this.informacoes[0].insInstitucionalSegmentos[0].insSegmentos.sgiId);
                    }
                  })
                  .catch(error => {
                    console.error(error);
                  });
              })
            .catch(error => {
                console.error(error);
            });

      

  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

.bg-light-gray {
  background-color: #f2f2f2; /* Cinza claro */
}


</style>
