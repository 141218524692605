<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-minha_conta">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <ul class="lista-sidebar_links">
                <li><router-link to="/meusDados" class="texto active">Meus Dados</router-link></li>
                <li><router-link to="/meusPedidos" class="texto">Meus Pedidos</router-link></li>
              </ul>
            </div>
            <div class="col-lg-8 mt-5 mt-lg-0">
              <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
              <h6 class="subtitulo h5 mb-4">Meus Dados</h6>
              <div class="col-12 col-md-12 col-lg-12 d-flex align-items-right justify-content-end">
                  <a href="#" class="btn btn-dark" @click="Logout()">Deslogar</a>
              </div>
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="4" v-show="false">
                      <v-text-field v-model="Clientes.cliId" label="Id" variant="underlined"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4" v-show="false">
                      <v-select v-model="Clientes.cctId" label="Tipo" :items="TipoClientes" item-title="cctNome"
                        item-value="cctId" variant="underlined">
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field v-model="Clientes.cliNomeEmpresa" label="Nome" variant="underlined"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="Clientes.cliDataCadastro" type="date" :disabled="Clientes.cliId !== 0"
                        label="Cadastro" variant="underlined" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="Clientes.cliTelefone" v-mask="'(##) ####-#####'" label="Telefone"
                        variant="underlined" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="Clientes.cliWhatsapp" v-mask="'(##) ####-#####'" label="Whatsapp"
                        variant="underlined" required></v-text-field>
                    </v-col>
                  
                    <v-col cols="12" md="4" v-if="Clientes.cctId == 1">
                      <v-text-field v-model="Clientes.cliDataAniversario" type="date" label="Data Nascimento"
                        variant="underlined" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field v-model="Clientes.cliEmail" label="E-mail" variant="underlined"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="Clientes.cctId == 1">
                      <v-text-field v-model="Clientes.cliCpf" v-mask="'###.###.###-##'" label="CPF" variant="underlined"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="Clientes.cliSenha" :type="senhaVisivel ? 'text' : 'password'" label="Senha"
                        variant="underlined" :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="toggleSenhaVisivel"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" v-if="Clientes.cctId == 2">
                      <v-text-field v-model="Clientes.cliCnpj" v-mask="'##.###.###/####-##'" label="CNPJ"
                        variant="underlined" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                      <v-text-field v-model="Clientes.cliRazaoSocial" label="Razão Social" variant="underlined"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                      <v-text-field v-model="Clientes.cliResponsavel" label="Responsável" variant="underlined"
                        required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              <form>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <a href="#" @click="Get()" class="btn" style="margin-right: 40px;">
                      Cancelar
                    </a>
                    <a href="#" v-if="Clientes.cliId != 0" class="btn" @click="Put()">
                      Atualizar
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1, SHA256 } from 'crypto-js';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';

export default {
  name: 'MeusDadosView',
  components: {
    CheckAcesso,
  },
  data: () => ({
    Clientes: {
      cliId: 0,
      cctId: "",
      cliVisivel: '',
      cliDataCadastro: '',
      cliNomeEmpresa: '',
      cliRazaoSocial: '',
      cliCnpj: '',
      cliCpf: '',
      cliTelefone: '',
      cliWhatsapp: '',
      cliResponsavel: '',
      cliEmail: '',
      cliSenha: '',
      cliAtivo: '',
      cliDataAniversario: '',
      cliCampoExtra1: '',
      cliCampoExtra2: '',
      cliCampoExtra3: '',
      cliCampoExtra4: '',
      cliCampoExtra5: '',
      cliTexto1: '',
      cliTexto2: '',
      cliTexto3: '',
      cliData1: '',
      cliData2: '',
    },
    TipoClientes: [],
    senhaVisivel: false,
    isEmailValid: false,
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
    ],
  }),
  methods: {
    Logout() {
      Cookies.remove(this.$CookieCliente);
      this.$store.commit('updateVariavelHeader1', '');
      window.location.reload();
      
    },
    formatarValor(valor) {
      if (valor != '' && valor != null) {
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      }
      else {
        valor = 0;
        return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
      }

    },
    formattedDate(value) {
      if (value != null || value != "") {
        return this.$moment(value).format('DD/MM/YYYY')
      }
      else {
        return "";
      }

    },
    toggleSenhaVisivel() {
      this.senhaVisivel = !this.senhaVisivel;
    },
    Put() {
      if (this.Clientes.cctId == '') {
        this.$mensagemAviso("Campo Tipo Cliente Obrigatório.")
      }
      else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
        this.$mensagemAviso("Campo Nome Cliente Obrigatório.")
      }
      else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
        this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
      }
      else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
        this.$mensagemAviso("Campo Razão Social Obrigatório.")
      }
      else if (this.Clientes.cliWhatsapp === '') {
        this.$mensagemAviso("Campo Whatsapp Obrigatório.")
      }
      else if (this.Clientes.cliEmail === '') {
        this.$mensagemAviso("Campo E-mail Obrigatório.")
      }
      else {
        if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
          this.Clientes.cliVisivel = true;
        }
        this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }

        if (this.Clientes.cliDataAniversario === "" || this.Clientes.cliDataAniversario === null) {
          this.Clientes.cliDataAniversario = null;
        }
        else {
          this.Clientes.cliDataAniversario = this.$moment(this.Clientes.cliDataAniversario).format('YYYY-MM-DD')
        }
        if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
          this.Clientes.cliData1 = null;
        }
        else {
          this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
        }
        if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
          this.Clientes.cliData2 = null;
        }
        else {
          this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
        }
        if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null) {
          this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
        }
        else {
          this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
        }
        if (this.Clientes.cliSenha != "" && this.Clientes.cliSenha != null) {
          this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
        }

        axios.put(`/Process/CliClientes/Update`, this.Clientes)
          .then(response => {
            if (response.status == 200) {
              this.$mensagemAvisoSucesso("Registro Alterado.")
              this.Get();
            }
          });
      }
    },
    Get() {
      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData) {
        const _dadosRecuperados = JSON.parse(cookieData);
        axios.get(`/Process/CliClienteTipo/GetAll`)
          .then(response => {
            this.TipoClientes = response.data;

          });

        axios.get(`/Process/CliClientes/GetById/` + _dadosRecuperados.usuarioId)
          .then(response => {
            this.Clientes = response.data;
           // console.log('Clientes')
           // console.log(this.Clientes)

            if (this.Clientes.cliDataCadastro != "" && this.Clientes.cliDataCadastro != null) {
              this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
            }
            else {
              this.Clientes.cliDataCadastro = '';
            }
            if (this.Clientes.cliDataAniversario != "" && this.Clientes.cliDataAniversario != null) {
              this.Clientes.cliDataAniversario = this.$moment(this.Clientes.cliDataAniversario).format('YYYY-MM-DD')
            }
            else {
              this.Clientes.cliDataAniversario = '';
            }

            this.Clientes.cliSenha = null;
            this.Clientes.cctId = this.Clientes.cliClienteTipo.cctId;
          });
      }
    },
  },
  created() {
    this.Get();
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color: #ffffff !important;
  font-size: 20px;
}
</style>
