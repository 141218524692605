<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-carrinho" :style="{backgroundImage:'url(' +  $imgURL + logintopo.pfcUrlImgFundo + ')',backgroundSize: 'cover',minHeight: '500px'}" v-if="logintopo!=''">
        <section class="">
          <div class="container" style="padding-bottom: 60px;">
              <div class="row">
                <div class="col-lg-12" style="padding-top: 60px;">
                  <div class="row">
                    <div class="col-lg-12" style="padding: 30px;">
                      <div class="row">
                         <h3 style="color:#ffffff;">{{ logintopo.pfcTituloTextoFinalizado }}</h3>
                          <div class="col-lg-12" >
                            <span v-html="logintopo.pfcTextoFinalizado"></span>
                          </div>
                          <div class="col-lg-12" style="padding-top: 60px;">
                            <a href="#" @click="VerMeusPedidos()" class="btn btn-primary px-5 mt-0" style="margin-bottom:20px;color:#ffffff;">Ver minhas cotações</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';
import { useHead } from '@vueuse/head';

export default {
  name: 'PedidoRealizadoView',
  components: {
    CheckAcesso,
  },
  data: () => ({
    logintopo: [],
    Formulario: {
      NomeDe: '',
      NomePara: '',
      Mensagem: '',
    },
    AbreSenha: false,
    AbreCad: false,
    TipoPgto: '',
    ValidacaoCartao: false,
    TextoComplementar: '',
    paymentId: 0,
    cliId: 0,
    NumeroPedido: '',
    contatoRemetente:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Pedido Enviado - Corofergs Soluções em Usinagem'
			})
		},
    VerMeusPedidos(){
      this.$router.push('/meuspedidos');
    },
   async CarregaPedido(){

    const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData) {
        const _dadosRecuperados = JSON.parse(cookieData);
   
    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
    if (dadosArmazenados) {
          dadosArmazenados = JSON.parse(dadosArmazenados);
          let _situacao = 'Aguardando';
          let _pspId = 1;

         //dados do pedido
              let _pedido = {
                  PedId: 0,
                  GrtId: 7,
                  CdtId: 28,
                  IdiId: 1,
                  PedDataPedido: moment().format('YYYY-MM-DD'),
                  PedOrigemPedido: 'Site',
                  CliId: _dadosRecuperados.usuarioId,
                  PedFormaPgto: '', //dadosArmazenados.pagamento[0].FormaPgto,
                  PedTipoFrete: dadosArmazenados.agenda[0].TipoFrete, //dadosArmazenados.agenda[0].Horarios.Tipo,
                  PedValorFrete: 0.00, // dadosArmazenados.agenda[0].Horarios.Valor,
                  CupId : 0,
                  PedCodigoCupom: dadosArmazenados.cupom.length > 0 ? dadosArmazenados.cupom[0].numCupom : '',
                  PedValorCupom: dadosArmazenados.cupom.length > 0 ? dadosArmazenados.cupom[0].ValorCupom : 0,
                  PedObsLoja: dadosArmazenados.agenda[0].Transportadora + ' - ' + dadosArmazenados.agenda[0].telefone,
                  PedRetornoPgto: '', //dadosArmazenados.pagamento[0].RetornoPgto,
                  PedCodigoRetornoPgto: '', //dadosArmazenados.pagamento[0].CodRetornoPgto.toString(),
                  PspId: _pspId,
                  PedSituacaoPedido: _situacao,
                  PedobsCliente: dadosArmazenados.agenda[0].Obs,
                }

               await axios.post(`/Process/PedPedidos/Add`, _pedido)
                        .then(response => {
                            if (response.data > 0) {
                                    _pedido.PedId = response.data;   
                                    this.NumeroPedido = _pedido.PedId;
                                    console.log('_pedido adicionado')
                                    console.log(_pedido)                
                            }
                        });

                //itens do pedido
                let _pedidoItens = [];
                if (dadosArmazenados.produtos != '') {
                    dadosArmazenados.produtos.forEach((itemprod) => {
                      _pedidoItens.push(
                          {
                            PdiId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedId: _pedido.PedId,
                            ProId: itemprod.proId,
                            PdiNomeProduto: itemprod.proNomeProduto,
                            PdiQtdeProduto: parseInt(itemprod.proOrdem),
                            PdiValorProduto: 0, //itemprod.proProdutosPrecos[0].ppcValorPor,
                            PdiValorTotalProduto: 0, // itemprod.proProdutosPrecos[0].ppcValorPor,
                            PdiDataCadastro: moment().format('YYYY-MM-DD'),
                          }
                      );
                    });
                }

                await _pedidoItens.forEach((itens) => {
                   axios.post(`/Process/PedPedidoItem/Add`, itens)
                        .then(response => {
                            if (response.data > 0) {
                              console.log('_pedidoItens adicionado')
                              console.log(itens)             
                            }
                        });
                })

               

                //itens adicional do pedido
                let _pedidoItensAvulsos = [];
                if (dadosArmazenados.avulsos != '') {
                    dadosArmazenados.avulsos.forEach((itemprod) => {
                      _pedidoItensAvulsos.push(
                          {
                            PiaId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedId: _pedido.PedId,
                            PraId: itemprod.praId,
                            PiaNomeProduto: itemprod.praNomeProduto,
                            PiaQtdeProduto: 1,
                            PiaValorProduto: itemprod.praValorPor,
                            PiaValorTotalProduto: itemprod.praValorPor,
                            PiaDataCadastro: moment().format('YYYY-MM-DD'),
                            PiaCampoExtra1: itemprod.praCampoExtra1,
                          }
                      );
                    });

                    await _pedidoItensAvulsos.forEach((itens) => {
                    axios.post(`/Process/PedPedidoItemAdicional/Add`, itens)
                          .then(response => {
                              if (response.data > 0) {
                                console.log('_pedidoItensAvulsos adicionado')
                                console.log(itens)         
                              }
                          });
                })
                }
               

                //pedido agendamento
               /* let _pedidoAgendamento = {
                  PagId: 0,
                  GrtId: 7,
                  CdtId: 28,
                  IdiId: 1,
                  PagDiaEntrega: dadosArmazenados.agenda[0].DataEntrega,
                  PagHoraIni: dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraIni,
                  PagMinIni: dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinIni,
                  PagHoraFim: dadosArmazenados.agenda[0].Horarios.Agendamento.ehrHoraFim,
                  PagMinFim: dadosArmazenados.agenda[0].Horarios.Agendamento.ehrMinFim,
                  PagUrlImagemEntrega:'',
                  PedId:_pedido.PedId,
                }

                await axios.post(`/Process/PedPedidoAgendamento/Add`, _pedidoAgendamento)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoAgendamento adicionado')
                                    console.log(_pedidoAgendamento)                
                            }
                        });*/


                  //pedido Endereço
                   let _pedidoEndereco = {
                    PdcId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PdcEndereco: dadosArmazenados.agenda[0].endereco,
                    PdcNumero: parseInt(dadosArmazenados.agenda[0].numero,10),
                    PdcComplemento: dadosArmazenados.agenda[0].complemento,
                    PdcObsEndereco:'',
                    pdcCep: dadosArmazenados.agenda[0].cep,
                    PdcPais:'',
                    PdcEstado:dadosArmazenados.agenda[0].estado,
                    PdcCidade:dadosArmazenados.agenda[0].cidade,
                    PdcBairro: dadosArmazenados.agenda[0].bairro,
                    CpaId:1,
                    CetId: 0, //dadosArmazenados.agenda[0].Estado!= null ? parseInt(dadosArmazenados.agenda[0].Estado,10): 0
                    CidId: 0, //dadosArmazenados.agenda[0].Cidade != null ? parseInt(dadosArmazenados.agenda[0].Cidade,10): 0
                    BaiId: 0, //dadosArmazenados.agenda[0].Bairro != null ? parseInt(dadosArmazenados.agenda[0].Bairro,10) : 0
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoEndereco adicionado')
                                    console.log(_pedidoEndereco)                
                            }
                        });


                 /* if (dadosArmazenados.mensagem != '') {
                   //pedido Mensagem
                   let _pedidoMensagem = {
                    PmsId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PmsUrlImagem: '',
                    PmsNomeDe: dadosArmazenados.mensagem[0].NomeDe,
                    PmsNomePara: dadosArmazenados.mensagem[0].NomePara,
                    PmsTexto: dadosArmazenados.mensagem[0].Mensagem,
                    PmsTelefoneRecebe: dadosArmazenados.agenda[0].TelefoneRecebe,
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoMensagem adicionado')
                                    console.log(_pedidoMensagem)                
                            }
                        });
                }*/

               /* if (dadosArmazenados.pagamento[0].FormaPgto != 'credit_card') {
                  //pedido Comprovante
                  let _pedidoComprovante = {
                    PpcId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PpcUrlImagem: dadosArmazenados.pagamento[0].FotoComprova,
                    PedId:_pedido.PedId,
                  }

                  await axios.post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                        .then(response => {
                            if (response.data > 0) {   
                                    console.log('_pedidoComprovante adicionado')
                                    console.log(_pedidoComprovante)                
                            }
                        });
                }*/
               /* if (dadosArmazenados.pagamento[0].RetornoPgto == 'approved'){
                  this.EnviaAviso();
                }

                if (dadosArmazenados.pagamento[0].UrlPix != ''){
                  this.EnviaEmailAviso(dadosArmazenados.pagamento[0].UrlPix);
                }*/
                this.EnviaAviso();
                return 1;
            }
        }
      },
    EnviaAviso() {

        axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
          this.templateEmail = response.data.filter(x=> x.mpdId == 3);

          const cookieData = Cookies.get(this.$CookieCliente);
          if (cookieData) {
            const _dadosRecuperados = JSON.parse(cookieData);

            //envia cliente
            let _dadosEmail = {
              nomeDe: 'Corofergs',
              nomePara:  _dadosRecuperados.nomeUsuario,
              assunto: 'Andamento Cotação: ' + this.NumeroPedido,
              destinatario: _dadosRecuperados.email,
              emailResposta: this.logintopo.pfcEmailRecebeAviso,
              textoEmail: this.templateEmail.mpdTexto.replace('[NomeCliente]', _dadosRecuperados.nomeUsuario).replace('[NumeroPedido]', this.NumeroPedido).replace('[Status]', 'Aguardando'),
              nomeAnexo: '',
              anexo: '',
              tipoAnexo: ''
            }
            axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
              .then(response => {
                if (response.data == 1) {
                  console.log('email enviado')
                }
              });
              
             //envia empresa
             let _dadosEmail2 = {
              nomeDe: 'Corofergs',
              nomePara: 'Corofergs',
              assunto: 'Nova cotação Site: ' + this.NumeroPedido,
              destinatario: this.logintopo.pfcEmailRecebeAviso,  
              emailResposta: this.logintopo.pfcEmailRecebeAviso,
              textoEmail: "Nova cotação no site, Cliente: " + _dadosRecuperados.nomeUsuario + ' - Cotação: ' + this.NumeroPedido,
              nomeAnexo: '',
              anexo: '',
              tipoAnexo: ''
            }
            axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
              .then(response => {
                if (response.data == 1) {
                  console.log('email 2 enviado')
                }
              });

          }
        });
    },
  },
  async created() {
    this.carregaMetas();
    
    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);

      if (dadosArmazenados) {
        dadosArmazenados = JSON.parse(dadosArmazenados);

       await axios.get('/Process/ConConfigFormasPagamento/GetById/4')
        .then(response => {
          this.logintopo = response.data;
          console.log('this.logintopo')
          console.log(this.logintopo)
          
        })
        .catch(error => {
          console.error(error);
        });

        await axios.get('/Process/InsInstitucional/institucional/16/contato')
          .then(response => {
            this.contatoRemetente = response.data;
          })
          .catch(error => {
            console.error(error);
          });

        console.log('dadosArmazenados')
        console.log(dadosArmazenados)

       let result = await this.CarregaPedido();
       if (result == 1){
          localStorage.removeItem(this.$DadosCompra);
          this.$store.commit('updateVariavelHeader2', '');
       }
    }
    else{
      this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho de Orçamento.")
      this.$router.push('/')
    }

  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color:#ffffff !important;
  font-size: 20px;
}
</style>
