<template>
    <div>
        <main class="main">
            <section class="main-carrinho" style="background-color: rgb(206 204 205);">
                <section class="">
                    <div class="container">
                        <div class="row py-5">
                            <div class="col-lg-5 hidden-xs hidden-sm">
                                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                                    v-if="textoCarrinho != ''">
                                    <img v-if="textoCarrinho[0].insInstitucionalImagens != ''"
                                        :src="$imgURL + textoCarrinho[0].insInstitucionalImagens[0].itgUrlImagem" />
                                </div>
                            </div>
                            <div class="col-lg-7">
                                <div class="box_content">
                                    <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">{{
                                        textoCarrinho[0].itiNome }}</h3>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                    <v-text-field class="input" v-model="numCep" v-mask="'#####-###'"
                                                        type="text" variant="underlined" placeholder="CEP"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <a href="#" @click.prevent="Buscacep(numCep)"
                                                        class="btn px-md-5 mt-0 mb-3">Verificar</a>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field class="input" v-model="endereco" type="text"
                                                        variant="underlined" placeholder="Endereço"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field class="input" v-model="numeroend" type="text"
                                                        variant="underlined" placeholder="Número"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="8">
                                                    <v-text-field class="input" v-model="complemento" type="text"
                                                        variant="underlined" placeholder="Complemento"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="5">
                                                    <v-text-field class="input" v-model="nomeCidade" type="text"
                                                        variant="underlined" placeholder="Cidade"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-text-field class="input" v-model="siglaEstado" v-mask="'AA'"
                                                        type="text" variant="underlined" placeholder="UF"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="5">
                                                    <v-text-field class="input" v-model="nomeBairro" type="text"
                                                        variant="underlined" placeholder="Bairro"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <strong>Tipo Frete </strong>
                                                    <v-radio-group v-model="recebeEntrega"
                                                        style="display: inline-flex;flex-direction: row;">
                                                        <v-radio :label="'FOB'" value="FOB" style="width: 80px;"></v-radio>
                                                        <v-radio :label="'CIF'" value="CIF"
                                                            style="width: 80px;"></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" md="4" v-if="recebeEntrega == 'CIF'">
                                                    <v-text-field v-model="NomePara2" label="Nome Transportadora"
                                                        variant="underlined" required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="TelefoneRecebe" v-if="recebeEntrega == 'CIF'"
                                                        v-mask="'(##) #####-####'" label="Telefone" variant="underlined"
                                                        required></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="12">
                                                    <v-text-field v-model="Observacao" label="Observações"
                                                        variant="underlined" required></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                    <div class="voltar border-top pt-4 hidden-md hidden-lg hidden-xl">
                                        <a class="voltar text-secondary" href="javascript:window.history.go(-1)"
                                            style="font-size: 13px;color:#333333;">Voltar</a>
                                    </div>
                                    <div class="box_content-footer pt-md-5">
                                        <div class="voltar hidden-sm hidden-xs"><a class="voltar text-secondary"
                                                href="javascript:window.history.go(-1)"
                                                style="font-size: 13px;color:#333333 !important;">Voltar</a></div>
                                        <div class="mt-4" v-if="textoCarrinho != ''">
                                            <a href="#" @click.prevent="carregaDados()"
                                                class="btn px-md-5 mt-0 mb-3">{{
                                                    textoCarrinho[0].itiTextoBtn1 }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </main>

    </div>
</template>

<script setup>
import { ref } from 'vue';
const date = ref(new Date());

const attrs = ref([
    {
        highlight: 'red',
        dates: new Date(),
    },
]);

</script>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import 'moment-timezone';

export default {
    name: 'CarrinhoView',
    components: {
    },
    data: () => ({
        textoCarrinho: [],
        valorTotal: 0,
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        endereco: '',
        numeroend: '',
        complemento: '',
        siglaEstado: '',
        nomeCidade: '',
        nomeBairro: '',
        numCep: '',
        Observacoes: '',
        Retornocep: {},
        showend: true,
        NomePara: '',
        NomePara2: '',
        TelefoneRecebe: '',
        Observacao: '',
        recebeEntrega: 'FOB',
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Endereço Entrega - Corofergs Soluções em Usinagem'
            })
        },
        formatarValor(valor) {
            if (valor != '' && valor != null) {
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            else {
                valor = 0;
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }

        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        AtualizaData() {
            this.DataEntrega = null;
            this.ArrayEntregas = [];
            this.selectedHorarioId = [];
        },
        carregaDados() {
            if (this.numCep == '') {
                this.$mensagemAviso("Preencha o CEP de entrega e clique em verificar.");
            } else if (this.endereco == '') {
                this.$mensagemAviso("Preencha o endereço de entrega");
            } else if (this.numeroend == null) {
                this.$mensagemAviso("Preencha o número do endereço de entrega");
            } else if (this.complemento == '') {
                this.$mensagemAviso("Preencha o complemento do endereço de entrega");
            } else if (this.nomeCidade == '') {
                this.$mensagemAviso("Preencha a cidade do endereço de entrega");
            } else if (this.siglaEstado == '') {
                this.$mensagemAviso("Preencha a sigla do estado do endereço de entrega");
            } else if (this.nomeBairro == '') {
                this.$mensagemAviso("Preencha o bairro do endereço de entrega");
            }  else if (this.recebeEntrega === 'CIF' && this.NomePara2 == "") {
                this.$mensagemAviso("Preencha os dados da Transportadora.")
            }
            else if (this.recebeEntrega === 'CIF' && this.TelefoneRecebe == "") {
                this.$mensagemAviso("Preencha o Telefone da Transportadora.")
            }
            else {
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.agenda[0] != null) {
                        dadosArmazenados.agenda[0] = {
                            telefone: this.TelefoneRecebe,
                            Transportadora: this.NomePara2,
                            TipoFrete: this.recebeEntrega,
                            estado: this.siglaEstado,
                            cidade: this.nomeCidade,
                            bairro: this.nomeBairro,
                            endereco: this.endereco,
                            numero: this.numeroend,
                            complemento: this.complemento,
                            cep: this.numCep,
                            Obs: this.Observacao
                        };
                    }
                    else {
                        dadosArmazenados.agenda.push({
                            telefone: this.TelefoneRecebe,
                            Transportadora: this.NomePara2,
                            TipoFrete: this.recebeEntrega,
                            estado: this.siglaEstado,
                            cidade: this.nomeCidade,
                            bairro: this.nomeBairro,
                            endereco: this.endereco,
                            numero: this.numeroend,
                            complemento: this.complemento,
                            cep: this.numCep,
                            Obs: this.Observacao
                        });
                    }
                }
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
                this.$router.push('/pedidorealizado')               
            }
        },
        formatPhoneNumber() {
            const phoneNumber = this.TelefoneRecebe.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

            if (phoneNumber.length <= 11) {
                // Se a quantidade de dígitos for menor que 11, assume-se que é um número brasileiro
                this.TelefoneRecebe = phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            } else {
                // Caso contrário, não aplica nenhuma máscara
                this.TelefoneRecebe = phoneNumber;
            }
        },
        Buscacep(value) {
            fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.endereco = this.Retornocep.logradouro;
                        this.numeroend = null;
                        this.complemento = null;
                        this.siglaEstado = this.Retornocep.uf;
                        this.nomeCidade = this.Retornocep.localidade;
                        this.nomeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        alert("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
    async created() {
        this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/23/textoendereco')
            .then(response => {
                this.textoCarrinho = response.data;

            })
            .catch(error => {
                console.error(error);
            });
    },
}
</script>

<style>
.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}

.my-calendar .vc-weekday-1,
.my-calendar .vc-weekday-7 {
    color: #6366f1;
}

.vc-sky-blue {
    --vc-accent-50: #f0f9ff;
    --vc-accent-100: #e0f2fe;
    --vc-accent-200: #bae6fd;
    --vc-accent-300: #7dd3fc;
    --vc-accent-400: #38bdf8;
    --vc-accent-500: #0ea5e9;
    --vc-accent-600: #0284c7;
    --vc-accent-700: #0369a1;
    --vc-accent-800: #075985;
    --vc-accent-900: #0c4a6e;
}</style>
