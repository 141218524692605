<template>
  <div>
    <CheckAcesso />
    <main class="main">
      <section class="main-minha_conta">
            <div class="container">
                <div class="row">
                    <!-- Sidenav -->
                    <div class="col-lg-2 col-xl-3">
                        <ul class="lista-sidebar_links">
                            <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                            <li><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                        </ul>
                    </div>
                    <!-- Main -->
                    <div class="col-lg-10 col-xl-9 mt-5 mt-lg-0">
                        <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                        <h6 class="subtitulo h5 mb-4">Meus Pedidos</h6>

                        <ul class="listaPedidos" v-if="PedidosCli!=''">
                            <li v-for="pedidocliente in PedidosCli" :key="pedidocliente.pedId">
                                <hr>
                                <div class="row">
                                    <div class="col-md-4 col-lg-2 mb-4 mb-lg-0">
                                        <div class="pedido_id">
                                            <i class="fas fa-clipboard-list text-success fa-2x"></i>
                                            <div style="padding-top:6px;">
                                                <h6 class="d-inline-block">Ped: {{ pedidocliente.pedId }}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-3 align-items-center">
                                      <small>Data Pedido</small><br>
                                        <div class="pedido_dados">{{ formattedDate(pedidocliente.pedDataPedido) }}</div>
                                    </div>
                                    <div class="col-md-3 col-lg-2 align-items-center">
                                        <small>Status</small><br>
                                        <div class="pedido_dados">{{ pedidocliente.pedSituacaoPedido }}</div>
                                    </div>
                                    <div class="col-md-3 col-lg-2 align-items-center">
                                        <small>Valor</small><br>
                                        <div class="pedido_dados">R$ {{ formatarValor(pedidocliente.valorTotal) }}</div>
                                    </div>
                                    <div class="col-lg-3 mt-4 mt-lg-0 d-flex align-items-center">
                                        <router-link :to="/meusPedidosDetalhe/ + pedidocliente.pedId" class="btn">Ver Detalhes</router-link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1, SHA256 } from 'crypto-js';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';

export default {
  name: 'MeusPedidosView',
  components: {
    CheckAcesso,
  },
  data: () => ({
    PedidosCli: [],
    ListaPedidoItem:[],
    ListaPedidoItemAdicional:[],
  }),
  methods: {
    formatarValor(valor) {
        if (valor!='' && valor!=null){
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        }
        else{
            valor = 0;
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        }
            
    },
    formattedDate(value) {
        if (value!=null || value != ""){
            return this.$moment(value).format('DD/MM/YYYY')
        }
        else{
            return "";
        }
        
    },
    calculaQtde(value){
      return 1
    },
    calculaValor(value){
       return 1
    },
    async calcularTotalPedido(pedido) {
      console.log('pedido')
          console.log(this.pedido)
          const response1 = await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + pedido.pedId);
          const response2 = await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + pedido.pedId);

          const listaPedidoItem = response1.data;
          const listaPedidoItemAdicional = response2.data;

          let valorProdutos = 0;
          let valorAvulsos = 0;

          console.log('listaPedidoItem')
          console.log(this.listaPedidoItem)
          console.log('listaPedidoItemAdicional')
          console.log(this.listaPedidoItemAdicional)
          if (listaPedidoItem) {
            listaPedidoItem.forEach(item => {
              valorProdutos += item.pdiValorTotalProduto;
            });
          }

          if (listaPedidoItemAdicional) {
            listaPedidoItemAdicional.forEach(item => {
              valorAvulsos += item.piaValorTotalProduto;
            });
          }

          return (valorProdutos + valorAvulsos + parseFloat(pedido.pedValorFrete)) - parseFloat(pedido.pedValorCupom);
    },
  },
  async created() {
      const cookieData = Cookies.get(this.$CookieCliente);
      if (cookieData) {
        const _dadosRecuperados = JSON.parse(cookieData);
       // console.log('_dadosRecuperados')
         //   console.log(_dadosRecuperados)
        await axios.get('/Process/PedPedidos/GetAllByCliId/' + _dadosRecuperados.usuarioId)
          .then(async response => {
            this.PedidosCli = response.data.sort((a, b) => {
                    const valorA = parseInt(a.pedId) || 0;
                    const valorB = parseInt(b.pedId) || 0;
                    return valorA - valorB;
                }).reverse();
            if (this.PedidosCli!=''){
              for (const pedido of this.PedidosCli) {
                pedido.valorTotal = await this.calcularTotalPedido(pedido);
              }
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}

span p {
  color:#ffffff !important;
  font-size: 20px;
}
</style>
