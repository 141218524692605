<template>
    <div>
        <main class="main">
            <section class="main-carrinho" style="background-color: rgb(206 204 205);">
                <section class="">
                    <div class="container">
                        <div class="row py-5">
                            <div class="col-lg-6 hidden-xs hidden-sm">
                                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                                    v-if="textoCarrinho != ''">
                                    <img v-if="textoCarrinho[0].insInstitucionalImagens != ''" style="width: 600px; padding-bottom: 30px;"
                                        :src="$imgURL + textoCarrinho[0].insInstitucionalImagens[0].itgUrlImagem" />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="box_content">
                                    <h3 v-if="textoCarrinho != ''" class="titulo-principal text-center">{{
                                        textoCarrinho[0].itiNome }}</h3>
                                    <table class="table table-cart">
                                        <thead>
                                            <tr class="text-center">
                                                <th scope="col-8">Produto</th>
                                                <th scope="col-1">Referência</th>
                                                <th scope="col-2">Qtde</th>
                                                <th scope="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="table-cart-line" v-for="(itemp, index) in DadosCompra.produtos"
                                                :key="index">
                                                <td class="d-flex align-items-center" style="text-align: center;">
                                                    <img class="img-cart img-fluid" v-if="itemp.proProdutosImagens != null && itemp.proProdutosImagens != ''"
                                                        style="border:1px solid #cccccc;height: 80px;width: auto;"
                                                        :src="$imgURL + itemp.proProdutosImagens[0].pimUrlImagem">
                                                    <img  :src="itemp.proCampoExtra7" alt=""
                                                    style="border:1px solid #cccccc;height: 80px;width: auto;">
                                                    <div style="padding-left: 5px;">
                                                        <h6 class="texto-destaque">{{ itemp.proNomeProduto }}</h6>
                                                        <p v-html="itemp.proTexto1"></p>
                                                    </div>
                                                </td>
                                                <td class="table-cart-data"
                                                    style="width:20%;text-align: center;font-size: 13px;">{{
                                                        itemp.proReferencia }}</td>
                                                <td class="table-cart-data" style="width:20%;text-align: center;font-size: 13px;">
                                                    <v-text-field v-model="itemp.proOrdem" label="Qtde" type="number"
                                                        variant="underlined" required></v-text-field>
                                                </td>
                                                <td class="table-cart-data">
                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" @click.prevent="ExcluiProduto(index)">
                                                        <i class="fa fa-window-close" style="color: black;font-size: 13px;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr class="table-cart-line" v-for="(itemp, index) in DadosCompra.avulsos" :key="index">
                                                <td class="d-flex align-items-center" style="text-align: center;">
                                                    <img class="img-cart img-fluid"
                                                        style="border:1px solid #cccccc;height: 80px;width: auto;"
                                                        :src="$imgURL + itemp.proProdutosAvulsosImagens[0].paiUrlImagem">
                                                        <div>
                                                            <h6 class="texto-destaque">{{ itemp.praNomeProduto }}</h6>
                                                            <input maxlength="40" v-if="itemp.praDestaque" type="text" class="form-control" v-model="itemp.praCampoExtra1" placeholder="Texto Balão">
                                                        </div>
                                                </td>
                                                <td class="table-cart-data"
                                                    style="width:20%;text-align: center;font-size: 13px;">{{
                                                        itemp.praReferencia }}</td>
                                                <td class="table-cart-data"
                                                    style="width:20%;text-align: center;font-size: 13px;"></td>
                                                <td class="table-cart-data">
                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir"
                                                        @click.prevent="ExcluiAvulso(index)">
                                                        <i class="fa fa-window-close"
                                                            style="color: black;font-size: 13px;"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="voltar border-top pt-4 hidden-md hidden-lg hidden-xl">
                                        <a class="voltar text-secondary" href="javascript:window.history.go(-1)"
                                            style="font-size: 13px;color:#333333;">Voltar</a>
                                    </div>
                                    <div class="box_content-footer pt-md-5">
                                        <div class="voltar hidden-sm hidden-xs"><a class="voltar text-secondary"
                                                href="javascript:window.history.go(-1)"
                                                style="font-size: 13px;color:#333333 !important;">Voltar</a></div>
                                        <div class="mt-4" v-if="textoCarrinho != ''">
                                            <a href="#" @click="AvancarEndereco()"
                                                class="btn px-md-5 mt-0 mb-3">{{
                                                    textoCarrinho[0].itiTextoBtn1 }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </main>

    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Cookies from 'js-cookie';

export default {
    name: 'CarrinhoView',
    components: {
    },
    data: () => ({
        textoCarrinho: [],
        ValorCupom: 0,
        numcupom: '',
        Produto: [],
        ProdutoImg: [],
        Qtde:1,
        infocall: [],
        valorTotal: 0,
        vProdutosExtras: [],
        ProdutoAvulsos: [],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        Cupons:[],
        CuponsProdutos:[],
        CuponsSegmentos:[],
        CuponsCliente:[],
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Carrinho - Corofergs Soluções em Usinagem'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                       // valorAvulsos += item.praValorPor;
                        vcount++;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                     //   valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                        vcount++;
                    });
                }
               // this.valorTotal = (valorProdutos + valorAvulsos)- this.ValorCupom;
                this.$store.commit('updateVariavelHeader2', vcount);
            }
            else{
                this.$store.commit('updateVariavelHeader2', '');
            }
           
        },
        ExcluiAvulso(index) {
           // Recupere os dados do carrinho do localStorage
            let dadosArmazenados = JSON.parse(localStorage.getItem(this.$DadosCompra)) || { avulsos: [] };

            // Verifique se o índice a ser removido está dentro dos limites do array
            if (index >= 0 && index < dadosArmazenados.avulsos.length) {
                // Remova o produto do array
                dadosArmazenados.avulsos.splice(index, 1);

                // Atualize os dados no localStorage
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));

                // Atualize a exibição do carrinho
                this.DadosCompra.avulsos = [...dadosArmazenados.avulsos];
                this.calculatotal();
            } else {
                console.error("Índice inválido ou produto não encontrado.");
            }
        },
        ExcluiProduto(index) {
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.produtos.splice(index, 1);
              
                if (dadosArmazenados.produtos.length == 0) {
                   // Limpar o array 'avulsos'
                    dadosArmazenados.avulsos = [];
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.$router.go(0);
                    this.$mensagemAviso("Carrinho vazio, escolha cesta e adicione no carrinho.")
                }
                else{
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    this.DadosCompra.produtos = [...dadosArmazenados.produtos];
                }
                this.calculatotal();
            }
        },
        async validarCupom(value){
           if (this.numcupom == ''){
            this.$mensagemAviso("Digite Código do cupom.")
           }
           else{
               if (this.Cupons.length > 0){
                 let _cupomresult = this.Cupons.filter(x=> x.cupCodigo == value);

                  if (_cupomresult != ''){
                    
                    let _cupomvalido = false;

                    let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                     if (dadosArmazenados) {
                        dadosArmazenados = JSON.parse(dadosArmazenados);


                    //valida se o cupom é específico para algum produto
                       await axios.get('/Process/CupCuponsProdutos/GetAllByCupId/' + _cupomresult[0].cupId + '/0' )
                        .then(response => {
                            this.CuponsProdutos = response.data;
                            //console.log('this.CuponsProdutos2')
                            //console.log(response.data)
                           // console.log(_cupomresult[0].cupId)
                            if (this.CuponsProdutos!= ''){
                                if (dadosArmazenados.produtos !='')
                                {
                                    let dadosprod = dadosArmazenados.produtos;
                                        dadosprod.forEach(element => {
                                            const _validaproduto = this.CuponsProdutos.filter(x => x.proId == element.proId);
                                            
                                            if (_validaproduto.length > 0) {
                                                //console.log('produtoscupom')
                                                _cupomvalido = true;
                                            }
                                        });
                                }
                                }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                    //valida se o cupom e específico para algum segmento
                      await axios.get('/Process/CupCuponsSegmentos/GetAllByCupId/' + _cupomresult[0].cupId + '/0' )
                        .then(response => {
                            this.CuponsSegmentos = response.data;
                            //console.log('this.CuponsSegmentos')
                           // console.log(this.CuponsSegmentos)
                            if (this.CuponsSegmentos!= ''){
                           if (dadosArmazenados.produtos !='')
                           {
                            let dadosseg = dadosArmazenados.produtos;
                            dadosseg.forEach(element => {
                                if (Array.isArray(element.proProdutosSegmentos)) {
                                    const segmentosValidos = element.proProdutosSegmentos.filter(segmento => {
                                        if (segmento.proSegmentos && this.CuponsSegmentos.some(cupomSegmento => cupomSegmento.psgId === segmento.proSegmentos.psgId)) {
                                            return true;
                                        }
                                        return false;
                                    });

                                    if (segmentosValidos.length > 0) {
                                       // console.log('segmentoscupom');
                                        _cupomvalido = true;
                                    }
                                }
                            });

                            }
                          }
                        })
                        .catch(error => {
                            console.error(error);
                        });

                    //valida se o cupom e específico para algum cliente                      
                            await axios.get('/Process/CupCuponsClientes/GetAllByCupId/' + _cupomresult[0].cupId + '/0')
                                .then(response => {
                                    this.CuponsCliente = response.data;
                                    //console.log('this.CuponsCliente')
                                    //console.log(this.CuponsCliente)
                                    if (this.CuponsCliente.length > 0){
                                       
                                        const cookieData = Cookies.get(this.$CookieCliente);
                                            if (cookieData) {
                                                const _dadosRecuperados = JSON.parse(cookieData);
                                               // console.log('_dadosRecuperados.ususarioId')
                                               // console.log(_dadosRecuperados.ususarioId)
                                                
                                                if (this.CuponsCliente.filter(x=> x.cliId == _dadosRecuperados.usuarioId).length > 0){
                                                   // console.log('clientescupom')
                                                    _cupomvalido = true;
                                                }
                                            }
                                    }
                                   
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                   

                      if (this.CuponsProdutos.length == 0 && this.CuponsSegmentos.length == 0 && this.CuponsCliente.length == 0 ) {
                            //console.log('geralcupom')
                            _cupomvalido = true;
                        }

                        if (_cupomvalido){

                           var valorAvulsos = 0;
                            var valorProdutos = 0;
                            if (dadosArmazenados.avulsos != '') {
                                dadosArmazenados.avulsos.forEach(item => {
                                    valorAvulsos += item.praValorPor;
                                });
                            }

                            if (dadosArmazenados.produtos != '') {
                                dadosArmazenados.produtos.forEach(item => {
                                    valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                                });
                            }

                            if (_cupomresult[0].cupTipoValor == 0){
                                this.ValorCupom = ((valorAvulsos + valorProdutos)*_cupomresult[0].cupValor)/100;
                                this.calculatotal();
                            }
                            else{
                                this.ValorCupom = _cupomresult[0].cupValor;
                                this.calculatotal();
                            }
                        }
                        else{
                            this.$mensagemAviso("Digite Código de cupom válido.")
                            this.ValorCupom = 0;
                            this.numcupom = '';
                            this.calculatotal();
                            }
                    }

                  } else{
                        this.$mensagemAviso("Digite Código de cupom válido.")
                        this.ValorCupom = 0;
                        this.calculatotal();
                  }
               }
               else{
                this.$mensagemAviso("Digite Código de cupom válido.")
                this.ValorCupom = 0;
                this.calculatotal();
               }
           }
        },
        AvancarEndereco(){
            if (this.numcupom != '' && this.ValorCupom != 0){
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    dadosArmazenados.cupom = [];
                    dadosArmazenados.cupom.push({numCupom: this.numcupom, ValorCupom: this.ValorCupom });
                    localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                    const cookieData = Cookies.get(this.$CookieCliente);
                        if (cookieData) {
                            const _dadosRecuperados = JSON.parse(cookieData);
                            let _usuario = {
                            email: _dadosRecuperados.email,
                            senha: _dadosRecuperados.senha,
                            };

                            if (_dadosRecuperados!= ''){
                                this.$router.push('/endereco');
                            }
                            else{
                                this.$router.push('/login');
                            }

                        } else {
                            this.$router.push('/login');
                        }
                }
            }
            else{
                //console.log("this.DadosCompra")
                //console.log(this.DadosCompra)
                let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.avulsos = this.DadosCompra.avulsos;
                dadosArmazenados.produtos = this.DadosCompra.produtos;
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                const cookieData = Cookies.get(this.$CookieCliente);
                        if (cookieData) {
                            const _dadosRecuperados = JSON.parse(cookieData);
                            let _usuario = {
                            email: _dadosRecuperados.email,
                            senha: _dadosRecuperados.senha,
                            };

                            if (_dadosRecuperados!= ''){
                                this.$router.push('/endereco');
                            }
                            else{
                                this.$router.push('/login');
                            }

                        } else {
                            this.$router.push('/login');
                        }
            }

        },
    },
    created() {
        this.carregaMetas();

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
            this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho de Orçamento.")
            this.calculatotal();
            this.$router.push('/')
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            this.DadosCompra = dadosArmazenados;
            if (this.DadosCompra.produtos.length == 0) {
                this.$mensagemAviso("Carrinho vazio, escolha produtos e adicione no carrinho de Orçamento.")
                this.$router.push('/')
            }
            this.calculatotal();
        }

        axios.get('/Process/InsInstitucional/institucional/22/textocarrinho')
            .then(response => {
                this.textoCarrinho = response.data;
               // console.log('textocarrinho')
               // console.log(this.textoCarrinho)
            })
            .catch(error => {
                console.error(error);
            });


            axios.get('/Process/CupCupons/GetAll')
            .then(response => {
                this.Cupons = response.data.filter(x=> moment(x.cupDataInicial).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') 
                                                   && moment(x.cupDataFinal).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') 
                                                   && x.cupAtivo
                                                   && x.cupQtdeUso > 0);
                //console.log('Cupons')
               // console.log(this.Cupons)
            })
            .catch(error => {
                console.error(error);
            });

    },
}
</script>

<style>.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
