<template>
    <Loader v-if="$loading.value"></Loader>
    <div v-if="montaHomeItens!=''">
       <div v-for="item in blocosHome" :key="item.hbhId">
           
          <!-- Slider -->
          <div v-if="item.homTipoBloco.htbId == 1">
                <v-carousel hide-delimiters class="tamCar" v-if="montaHomeItens.length > 0" style="height: 100% !important;" :cycle="true" :cycle-interval="5000">
                  <v-carousel-item v-for="slider in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="slider.hbiId">
                    <a v-if="slider.hbiLink" :href="slider.hbiLink" target="_blank">
                       <img v-if="slider!=''" :src="$imgURL + slider.hbiUrlImg" style="width: 100%;" />
                    </a>
                    <img v-else :src="$imgURL + slider.hbiUrlImg" style="width: 100%;" />
                  </v-carousel-item>
              </v-carousel>
           </div>

           <div v-else-if="item.homTipoBloco.htbId == 2">
                <div v-if="montaHomeItens.length > 0" style="padding-top: 20px;padding-bottom: 20px;text-align: center;" >
                        <div class="heading-center" style="padding-bottom: 20px;">
                            <h2 class="title">{{ item.hbhNomeBloco }}
                            </h2><!-- End .title -->
                        </div><!-- End .heading-left -->      
                        <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 5 }, 0: { slidesPerView: 1 } }" :autoplay="{ delay: 3000 }" class="carousel-testimony owl-carousel" style="height: 130px !important;">
                            <SwiperSlide v-for="bannercar in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="bannercar.hbiId" style="padding: 3px;">
                                <a v-if="bannercar.hbiLink!=''" :href="bannercar.hbiLink" target="_blank">
                                    <img v-if="bannercar!=''" :src="$imgURL + bannercar.hbiUrlImg" style="width: 240px;" />
                                </a>
                                <img v-else :src="$imgURL + bannercar.hbiUrlImg"  />
                            </SwiperSlide>
                        </Swiper>
                </div>
          </div>

           <!-- Carrossel 5 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 3" style="position:relative;padding-bottom:20px;">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
                </div>
                  <div v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                    <Swiper :navigation="true" :pagination="{ clickable: true }" :slides-per-view="5" :breakpoints="{ 768: { slidesPerView: 5 }, 0: { slidesPerView: 2 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4car.hbiId">
                                <div class="product" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media" style="text-align: center;">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image" style="height: 150px">
                                            <img v-else-if="prod4car.proProdutos.proCampoExtra7!='' && prod4car.proProdutos.proCampoExtra7!=null" :src="prod4car.proProdutos.proCampoExtra7" alt="" class="product-image" style="height: 150px">
                                        </router-link>
                                        <div class="" style="padding: 0px;text-align: center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align: center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size: 14px !important;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if=" prod4car.proProdutos.proVisivel">
                                            <small class="old-price">Ref: {{ prod4car.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>

          </div>

           <!-- 1 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 4">
              <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-sm-12 col-lg-12" v-for="banner1 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner1.hbiId">
                        <a v-if="banner1.hbiLink!=''" :href="banner1.hbiLink" target="_blank">
                          <img v-if="banner1!=''" :src="$imgURL + banner1.hbiUrlImg" style="width: 100%;" />
                        </a>
                        <img v-else :src="$imgURL + banner1.hbiUrlImg" style="width: 100%;" />
                    </div>
                  </div>
              </div>
          </div>

           <!-- 2 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 5">
              <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-sm-6 col-lg-6" v-for="banner2 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner2.hbiId">
                        <a v-if="banner2.hbiLink!=''" :href="banner2.hbiLink" target="_blank">
                          <img v-if="banner2!=''" :src="$imgURL + banner2.hbiUrlImg" style="width: 100%;" />
                        </a>
                        <img v-else :src="$imgURL + banner2.hbiUrlImg" style="width: 100%;" />
                    </div>
                  </div>
              </div>
          </div>

          <!-- 3 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 6">
            <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-sm-4 col-lg-4" v-for="banner3 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner3.hbiId">
                        <a v-if="banner3.hbiLink!=''" :href="banner3.hbiLink" target="_blank">
                          <img v-if="banner3!=''" :src="$imgURL + banner3.hbiUrlImg" style="width: 100%;" />
                        </a>
                        <img v-else :src="$imgURL + banner3.hbiUrlImg" style="width: 100%;" />
                    </div>
                  </div>
              </div>
          </div>

          <!-- 4 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 7">
            <div class="container" v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-sm-3 col-lg-3" v-for="banner4 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner4.hbiId">
                        <a v-if="banner4.hbiLink!=''" :href="banner4.hbiLink" target="_blank">
                          <img v-if="banner4!=''" :src="$imgURL + banner4.hbiUrlImg" style="width: 100%;" />
                        </a>
                        <img v-else :src="$imgURL + banner4.hbiUrlImg" style="width: 100%;" />
                    </div>
                  </div>
              </div>
          </div>

          <!-- 6 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 8">
              <div class="container-fluid" v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-sm-2 col-lg-2" v-for="banner6 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="banner6.hbiId">
                        <a v-if="banner6.hbiLink!=''" :href="banner6.hbiLink" target="_blank">
                          <img v-if="banner6!=''" :src="$imgURL + banner6.hbiUrlImg" style="width: 100%;" />
                        </a>
                        <img v-else :src="$imgURL + banner6.hbiUrlImg" style="width: 100%;" />
                    </div>
                  </div>
              </div>
          </div>

          <!-- 1 Banner Linha -->
          <div v-else-if="item.homTipoBloco.htbId == 9">
              <section v-if="montaHomeItens.length > 0" style="padding-top: 20px;padding-bottom: 20px;" >
                  <div v-for="bannerlinha in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="bannerlinha.hbiId" >
                      <a v-if="bannerlinha.hbiLink!=''" :href="bannerlinha.hbiLink" target="_blank">
                        <img v-if="bannerlinha!=''" :src="$imgURL + bannerlinha.hbiUrlImg" style="width: 100%;" />
                      </a>
                      <img v-else :src="$imgURL + bannerlinha.hbiUrlImg" style="width: 100%;" />
                  </div>
              </section>
          </div>

           <!-- 2 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 10">
            <div class="mb-4"></div><!-- End .mb-4 -->

                <div class="container for-you">
                    <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                        <div class="heading-center">
                            <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                        </div><!-- End .heading-left -->
                    </div><!-- End .heading -->

                    <div class="products">
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-6 col-lg-6 mt-3" v-for="prod2 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod2.hbiId">
                                <div class="product product-2" v-if="prod2.proProdutos!=''">
                                    <figure class="product-media" style="text-align:center;">
                                        <span v-if="prod2.proProdutos.proEtiqueta!='' && prod2.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod2.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                            <img v-if="prod2.proProdutos.proCampoExtra10!='' && prod2.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod2.proProdutos.proCampoExtra10" alt="" class="product-image" style="height: 350px">
                                            <img v-else-if="prod2.proProdutos.proCampoExtra7!='' && prod2.proProdutos.proCampoExtra7!=null" :src="prod2.proProdutos.proCampoExtra7" alt="" class="product-image" style="height: 350px">
                                        </router-link>
                                        <div class="" style="text-align: center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)"
                                                class="btn-product" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align: center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                              {{ prod2.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title --> 
                                        <div class="product-price" v-if="prod2.proProdutos.proVisivel">
                                            <small class="old-price">Ref: {{ prod2.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .products -->
                </div><!-- End .container -->
          </div>

           <!-- 3 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 11">
              <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-4 mt-3" v-for="prod3 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod3.hbiId">
                              <div class="product product-2" v-if="prod3.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod3.proProdutos.proEtiqueta!='' && prod3.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod3.proProdutos.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                          <img v-if="prod3.proProdutos.proCampoExtra10!='' && prod3.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod3.proProdutos.proCampoExtra10" alt="" class="product-image" style="width: 100%;">
                                          <img v-else-if="prod3.proProdutos.proCampoExtra7!='' && prod3.proProdutos.proCampoExtra7!=null" :src="prod3.proProdutos.proCampoExtra7" alt="" class="product-image" style="width: 100%;">
                                      </router-link>
                                      <div class="" style="text-align: center;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)"
                                              class="btn-product" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body" style="text-align: center;">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                            {{ prod3.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod3.proProdutos.proVisivel">
                                         <small class="old-price">Ref: {{ prod3.proProdutos.proReferencia }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- 4 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 12">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-6 col-md-4 col-lg-3 mt-3" v-for="prod4 in montaHomeItens.filter(x=> x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4.hbiId">
                              <div class="product product-2" v-if="prod4.proProdutos!=''">
                                  <figure class="product-media" style="text-align:center;">
                                      <span v-if="prod4.proProdutos.proEtiqueta!='' && prod4.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4.proProdutos.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                          <img v-if="prod4.proProdutos.proCampoExtra10!='' && prod4.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4.proProdutos.proCampoExtra10" alt="" class="product-image" style="height: 250px">
                                          <img v-else-if="prod4.proProdutos.proCampoExtra7!='' && prod4.proProdutos.proCampoExtra7!=null" :src="prod4.proProdutos.proCampoExtra7" alt="" class="product-image" style="height: 250px">
                                      </router-link>
                                      <div class="" style="text-align: center;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)"
                                              class="btn-product" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body" style="text-align: center;">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                            {{ prod4.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proProdutos.proVisivel">
                                          <small class="old-price">Ref: {{ prod4.proProdutos.proReferencia }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- Carrossel 4 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 13" style="background-color: #7c8295 !important;">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbhNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
              
                  <div v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;" >
                    <Swiper :navigation="false" :space-between="10" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 2 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="prod4car.hbiId">
                                <div class="product product-2" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media" style="border: 1px solid #cccccc;text-align:center;">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image" style="height: 230px;width: 230px;padding: 10px;">
                                            <img v-else-if="prod4car.proProdutos.proCampoExtra7!='' && prod4car.proProdutos.proCampoExtra7!=null" :src="prod4car.proProdutos.proCampoExtra7" alt="" class="product-image" style="height: 230px;width: 230px;padding: 10px;">
                                        </router-link>
                                        <div class="" style="text-align: center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align: center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size:15px;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proVisivel">
                                            <small class="old-price"> Ref: {{ prod4car.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>
              </div>
          </div>

          <!-- Carrossel 4 Informações -->
          <div v-else-if="item.homTipoBloco.htbId == 14" style="background-color: #eaebef !important;">
              <div class="icon-boxes-container">
                <div class="container">
                      <div class="row">
                            <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 4 } }" class="carousel-testimony owl-carousel" style="height: 130px !important;">
                                <SwiperSlide v-for="carro4inf in montaHomeItens.filter(x => x.hbhId == item.hbhId && x.hbiVisivel)" :key="carro4inf.hbiId">
                                    <div class="" style="width: 280px;justify-content: center;">
                                        <div class="icon-box icon-box-side">
                                            <span class="icon-box-icon text-dark" style="display: block; margin: 0 auto 10px;">
                                                <img v-if="carro4inf !== ''" :src="$imgURL + carro4inf.hbiUrlImg" style="height: 50px; display: block;" />
                                            </span>
                                            <div class="icon-box-content">
                                                <h3 class="icon-box-title" style="font-size: 18px;width: 220px;padding: 5px;">{{carro4inf.hbiLink}}</h3>
                                                <p>{{carro4inf.hbiTexto}}</p>
                                            </div>
                                            <!-- End .icon-box-content -->
                                        </div>
                                        <!-- End .icon-box -->
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                       </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
    <!-- aqui mobile-->
    <div v-if="montaHomeItensM!=''">
       <div v-for="item in blocosHomeM" :key="item.hbmId">
           
          <!-- Slider -->
          <div v-if="item.homTipoBloco.htbId == 1">
                <v-carousel hide-delimiters class="tamCar" v-if="montaHomeItensM.length > 0" style="height: 100% !important;" :cycle="true" :cycle-interval="5000">
                  <v-carousel-item v-for="slider in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="slider.himId">
                    <a :href="slider.himLink" target="_blank">
                       <img v-if="slider!=''" :src="$imgURL + slider.himUrlImg" style="width: 100%;" />
                    </a>
                  </v-carousel-item>
              </v-carousel>
           </div>

           <div v-else-if="item.homTipoBloco.htbId == 2">
            <div v-if="montaHomeItensM.length > 0" style="padding-top: 40px;padding-bottom: 20px;text-align: center;" >  
                <div class="heading-center" style="padding-bottom: 10px;">
                  <h4 class="title" style="font-size: 22px;">{{ item.hbmNomeBloco }}
                </h4><!-- End .title -->
                </div>
                <v-carousel :show-arrows="false" hide-delimiter-background style="height: 120px !important;" :cycle="true" :cycle-interval="5000"> 
                  <template v-for="(bannercar, index) in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="bannercar.himId"> 
                       <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index"> 
                    <v-row class="flex-nowrap" style="height:100%"> 
                    <template v-for="(n,i) in columns"> 
                        <template v-if="(+index + i) < montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel).length"> 
                        <v-col :key="i"> 
                            <v-sheet v-if="(+index + i) < montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel).length"  height="100%">
                            <v-row class="fill-height" align="center" justify="center">
                                <a :href="montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)[+index + i].himLink" target="_blank">
                                    <img :src="$imgURL + montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)[+index + i].himUrlImg" style="width: 100%;" />
                                </a>
                            </v-row>
                            </v-sheet>
                        </v-col> 
                        </template> 
                    </template> 
                    </v-row> 
                </v-carousel-item> 
                </template> 
                 </v-carousel>  
                  </div>
          </div>

           <!-- Carrossel 5 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 3">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 20px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
                </div>
                  <div v-if="montaHomeItensM.length > 0" style="padding-top: 0px;padding-bottom: 20px;" >
                    <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="1" :breakpoints="{ 768: { slidesPerView: 5 }, 0: { slidesPerView: 1 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="prod4car.himId">
                                <div class="product" v-if="prod4car.proProdutos!='' && prod4car.proProdutos!=null">
                                    <figure class="product-media">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image" style="width: 100%;">
                                        </router-link>
                                        <div class="" style="text-align:center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align: center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size: 25px !important;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proVisivel" style="justify-content: center;">
                                            <small class="old-price">Ref: {{ prod4car.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>

          </div>

           <!-- 1 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 4">
              <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-12 col-lg-12" v-for="banner1 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner1.himId">
                        <a :href="banner1.himLink" target="_blank">
                          <img v-if="banner1!=''" :src="$imgURL + banner1.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

           <!-- 2 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 5">
              <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-6 col-lg-6" v-for="banner2 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner2.himId">
                        <a :href="banner2.himLink" target="_blank">
                          <img v-if="banner2!=''" :src="$imgURL + banner2.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 3 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 6">
            <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;">
                  <div class="row">
                    <div class="col-4 col-lg-4" v-for="banner3 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner3.himId">
                        <a :href="banner3.himLink" target="_blank">
                          <img v-if="banner3!=''" :src="$imgURL + banner3.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 4 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 7">
            <div class="container" v-if="montaHomeItensM.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-3 col-lg-3" v-for="banner4 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner4.himId">
                        <a :href="banner4.himLink" target="_blank">
                          <img v-if="banner4!=''" :src="$imgURL + banner4.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 6 Banner -->
          <div v-else-if="item.homTipoBloco.htbId == 8">
              <div class="container-fluid" v-if="montaHomeItensM.length > 0" style="padding-top: 60px;padding-bottom: 60px;">
                  <div class="row">
                    <div class="col-2 col-lg-2" v-for="banner6 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="banner6.himId">
                        <a :href="banner6.himLink" target="_blank">
                          <img v-if="banner6!=''" :src="$imgURL + banner6.himUrlImg" style="width: 100%;" />
                        </a>
                    </div>
                  </div>
              </div>
          </div>

          <!-- 1 Banner Linha -->
          <div v-else-if="item.homTipoBloco.htbId == 9">
              <section v-if="montaHomeItensM.length > 0" style="padding-top: 20px;padding-bottom: 20px;" >
                  <div v-for="bannerlinha in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="bannerlinha.himId" >
                      <a :href="bannerlinha.himLink" target="_blank">
                        <img v-if="bannerlinha!=''" :src="$imgURL + bannerlinha.himUrlImg" style="width: 100%;" />
                      </a>
                  </div>
              </section>
          </div>

           <!-- 2 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 10">
            <div class="mb-4"></div><!-- End .mb-4 -->

                <div class="container for-you">
                    <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                        <div class="heading-center">
                            <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                        </div><!-- End .heading-left -->
                    </div><!-- End .heading -->

                    <div class="products">
                        <div class="row justify-content-center">
                            <div class="col-6 col-md-6 col-lg-6 mt-3" v-for="prod2 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod2.himId">
                                <div class="product product-2" v-if="prod2.proProdutos!='' && prod2.proProdutos!=null">
                                    <figure class="product-media">
                                        <span v-if="prod2.proProdutos.proEtiqueta!=null && prod2.proProdutos.proEtiqueta!=''" class="product-label label-sale">{{prod2.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                            <img style="width: 100%;" v-if="prod2.proProdutos.proCampoExtra10!='' && prod2.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod2.proProdutos.proCampoExtra10" alt="" class="product-image">
                                        </router-link>
                                        <div class="" style="text-align:center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align:center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title" style="font-size:15px;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod2.proProdutos.proId, prod2.proProdutos.proNomeProduto)">
                                              {{ prod2.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title --> 
                                        <div class="" v-if="prod2.proProdutos.proVisivel" style="text-align:center;">
                                            <small class="old-price">Ref: {{ prod2.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                            </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .products -->
                </div><!-- End .container -->
          </div>

           <!-- 3 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 11">
              <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-4 col-md-4 col-lg-4 mt-3" v-for="prod3 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod3.himId">
                              <div class="product product-2" v-if="prod3.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod3.proProdutos.proEtiqueta!='' && prod3.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod3.proProdutos.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                          <img style="width: 100%;" v-if="prod3.proProdutos.proCampoExtra10!='' && prod3.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod3.proProdutos.proCampoExtra10" alt="" class="product-image">
                                      </router-link>
                                      <div class="" style="text-align:center;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)"
                                              class="btn-product" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body" style="text-align:center;">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod3.proProdutos.proId, prod3.proProdutos.proNomeProduto)">
                                            {{ prod3.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="" v-if="prod3.proProdutos.proVisivel">
                                         <small class="old-price">Ref: {{ prod3.proProdutos.proReferencia }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- 4 Produtos por linha -->
          <div v-else-if="item.homTipoBloco.htbId == 12">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->

                  <div class="products">
                      <div class="row justify-content-center">
                          <div class="col-3 col-md-4 col-lg-3 mt-3" v-for="prod4 in montaHomeItensM.filter(x=> x.hbmId == item.hbmId && x.himVisivel)" :key="prod4.himId">
                              <div class="product product-2" v-if="prod4.proProdutos!=''">
                                  <figure class="product-media">
                                      <span v-if="prod4.proProdutos.proEtiqueta!='' && prod4.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4.proProdutos.proEtiqueta}}</span>
                                      <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                          <img style="width: 100%;" v-if="prod4.proProdutos.proCampoExtra10!='' && prod4.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4.proProdutos.proCampoExtra10" alt="" class="product-image">
                                      </router-link>
                                      <div class="" style="text-align:center;">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)"
                                              class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                          </router-link>
                                      </div><!-- End .product-action -->
                                  </figure><!-- End .product-media -->

                                  <div class="product-body" style="text-align:center;">
                                      <div class="product-cat">
                                      </div><!-- End .product-cat -->
                                      <h3 class="product-title">
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4.proProdutos.proId, prod4.proProdutos.proNomeProduto)">
                                            {{ prod4.proProdutos.proNomeProduto}}
                                        </router-Link>
                                      </h3><!-- End .product-title -->
                                      <div class="product-price" v-if="prod4.proProdutos.proVisivel">
                                          <small class="old-price">Ref: {{ prod4.proProdutos.proReferencia }}</small>
                                      </div><!-- End .product-price -->
                                      <div class="product-price" v-else>
                                          <span class="new-price">Indisponível</span>
                                      </div><!-- End .product-price -->
                                  </div><!-- End .product-body -->
                              </div><!-- End .product -->
                          </div><!-- End .col-sm-6 col-md-4 col-lg-3 -->
                      </div><!-- End .row -->
                  </div><!-- End .products -->
              </div><!-- End .container -->
          </div>

          <!-- Carrossel 4 Produtos -->
          <div v-else-if="item.homTipoBloco.htbId == 13" style="background-color: #7c8295 !important;">
            <div class="mb-4"></div><!-- End .mb-4 -->

              <div class="container for-you">
                  <div class="heading heading-flex mb-3" style="justify-content: center;padding-top: 60px;">
                      <div class="heading-center">
                          <h2 class="title">{{ item.hbmNomeBloco }}</h2><!-- End .title -->
                      </div><!-- End .heading-left -->
                  </div><!-- End .heading -->
              
                  <div v-if="montaHomeItens.length > 0" style="padding-top: 60px;padding-bottom: 60px;" >
                    <Swiper :navigation="false" :space-between="10" :pagination="{ clickable: true }" :slides-per-view="4" :breakpoints="{ 768: { slidesPerView: 4 }, 0: { slidesPerView: 2 } }" class="carousel-testimony owl-carousel">
                        <SwiperSlide v-for="prod4car in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="prod4car.himId">
                                <div class="product product-2" v-if="prod4car.proProdutos!=''">
                                    <figure class="product-media" style="border: 1px solid #cccccc;">
                                        <span v-if="prod4car.proProdutos.proEtiqueta!='' && prod4car.proProdutos.proEtiqueta!=null" class="product-label label-sale">{{prod4car.proProdutos.proEtiqueta}}</span>
                                        <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                            <img style="width: 100%;" v-if="prod4car.proProdutos.proCampoExtra10!='' && prod4car.proProdutos.proCampoExtra10!=null" :src="$imgURL + prod4car.proProdutos.proCampoExtra10" alt="" class="product-image">
                                        </router-link>
                                        <div class="" style="text-align:center;">
                                            <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)"
                                                class="btn-product btn-quickview" title=""><span>Ver detalhes</span>
                                            </router-link>
                                        </div><!-- End .product-action -->
                                    </figure><!-- End .product-media -->

                                    <div class="product-body" style="text-align:center;">
                                        <div class="product-cat">
                                        </div><!-- End .product-cat -->
                                        <h3 class="product-title">
                                          <router-link :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">
                                              {{ prod4car.proProdutos.proNomeProduto}}
                                          </router-Link>
                                        </h3><!-- End .product-title -->
                                        <div class="product-price" v-if="prod4car.proProdutos.proVisivel">
                                            <small class="old-price">Ref: {{ prod4car.proProdutos.proReferencia }}</small>
                                        </div><!-- End .product-price -->
                                        <div class="product-price" v-else>
                                            <span class="new-price">Indisponível</span>
                                        </div><!-- End .product-price -->
                                    </div><!-- End .product-body -->
                                </div><!-- End .product -->
                        </SwiperSlide>
                    </Swiper>
                  </div>
              </div>
          </div>

          <!-- Carrossel 4 Informações -->
          <div v-else-if="item.homTipoBloco.htbId == 14">
              <div class="icon-boxes-container">
                <div class="container">
                      <div class="row">
                        <v-carousel  :show-arrows="false" hide-delimiter-background class="carousel-testimony owl-carousel" style="height: 120px !important;" v-if="montaHomeItensM.length > 0" :cycle="true" :cycle-interval="5000">
                            <v-carousel-item v-for="carro4inf in montaHomeItensM.filter(x => x.hbmId == item.hbmId && x.himVisivel)" :key="carro4inf.himId">
                                <div class="icon-box icon-box-side" style="display: inline-flex;padding-left: 20px;">
                                          <span class="icon-box-icon text-dark">
                                            <img v-if="carro4inf!=''" :src="$imgURL + carro4inf.himUrlImg" style="width: 50px;padding-right: 10px;" />
                                          </span>
                                          <div class="icon-box-content">
                                              <h3 class="icon-box-title">{{carro4inf.himLink}}</h3><!-- End .icon-box-title -->
                                              <p>{{carro4inf.himTexto}}</p>
                                          </div><!-- End .icon-box-content -->
                                      </div><!-- End .icon-box -->
                            </v-carousel-item>
                        </v-carousel>

                       </div>
                    </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Loader from '@/components/PageLoader.vue'

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Loader,
  },
  data: () => ({
    montaHomeItens:[],
    blocosHome:[],
    montaHomeItensM:[],
    blocosHomeM:[],
  }),
  methods: {
    formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
    carregaMetas() {
			useHead({
				title: 'Home - Corofergs Soluções em Usinagem'
			})
		},
    AddCarrinho(valueProd){
      console.log(valueProd);
    },
    AjustaUrl(value, nome) {
            let urlFriendlyNomeProduto = nome
                .normalize("NFD") // Normaliza para decompor os caracteres acentuados
                .replace(/[\u0300-\u036f]/g, "") // Remove os caracteres acentuados
                .replace(/[^\w\s-]/g, "") // Remove caracteres especiais
                .replace(/\s+/g, "-") // Substitui espaços em branco por hífens
                .toLowerCase(); // Converte para minúsculas

            return `${value}/${urlFriendlyNomeProduto}`;
        },
  },
  async created() {
   this.carregaMetas();

   if (window.innerWidth > 768)
   {
   await axios.get('/Process/HomBlocosHomeItens/GetAll')
         .then(response => {
         this.montaHomeItens = response.data.sort((a, b) => {
                          const valorA = parseInt(a.hbiOrdem) || 0;
                          const valorB = parseInt(b.hbiOrdem) || 0;
                          return valorA - valorB;
                        });
         console.log('this.montaHomeItens')
         console.log(this.montaHomeItens)
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/HomBlocosHome/GetAll')
         .then(response => {
         this.blocosHome = response.data.sort((a, b) => {
                          const valorA = parseInt(a.hbhOrdem) || 0;
                          const valorB = parseInt(b.hbhOrdem) || 0;
                          return valorA - valorB;
                        });
        console.log('this.blocosHome')
        console.log(this.blocosHome)
       })
       .catch(error => {
         console.error(error);
       });

   }
   else{
    axios.get('/Process/HomBlocosHomeItensMobile/GetAll')
         .then(response => {
         this.montaHomeItensM = response.data.sort((a, b) => {
                          const valorA = parseInt(a.hbiOrdem) || 0;
                          const valorB = parseInt(b.hbiOrdem) || 0;
                          return valorA - valorB;
                        });
         console.log('this.montaHomeItensM')
        console.log(this.montaHomeItensM)
       })
       .catch(error => {
         console.error(error);
       });

       axios.get('/Process/HomBlocosHomeMobile/GetAll')
         .then(response => {
         this.blocosHomeM = response.data.sort((a, b) => {
                          const valorA = parseInt(a.hbmOrdem) || 0;
                          const valorB = parseInt(b.hbmOrdem) || 0;
                          return valorA - valorB;
                        });
        console.log('this.blocosHomeM')
        console.log(this.blocosHomeM)
       })
       .catch(error => {
         console.error(error);
       });
   }
       
  },
  computed: {
    columns() {
      return 2;
    }
  },
}
</script>

<style>


   .das {
            display: block !important;
        }

    .slider-text h2 p{
      font-size: 60px;
      color:#081624;
      padding:10px;
      display: inline-block;
      background: #99CC33;
      line-height: 1;
    }

    .swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden; 
    height: 400px !important;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}

.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #000000 !important;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -5px) !important;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
}

.swiper-button-prev, .swiper-button-next{
    color:#000000 !important;
}
@media (max-width:767px) {

    .swiper, swiper-container {
        overflow: auto !important; 
         height: 500px !important; 
    }

    .product-price {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25;
    color: #0a98f8;
    margin-bottom: 13px;
}
}

</style>
