<template>
  <main>
    <div class="slider-area ">
      <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
        v-if="blogtopo != '' && blogtopo[0].insInstitucionalImagens != ''"
        :style="{ backgroundImage: 'url(' + $imgURL + blogtopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap pt-100">
                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{ blogtopo[0].itiNome }}</h2>
                <!--nav aria-label="breadcrumb ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Vídeos</a></li>
                  </ol>
                </!--nav-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Blog section  -->
    <section class="blog_area section-padding">

      <div v-if="!vshowprod" class="container">
      <div class="row">
          <aside  class="col-lg-12 mt-4">
              <div class="sidebar sidebar-shop">
                  <div class="widget widget-collapsible">
                      <div class="collapse show" id="widget-1">
                          <div class="widget-body alinhaprod">
                              <div class="" v-for="seg in SegmentoCatalogo" :key="seg.sgiId"
                                  style="line-height: 2;">
                                  <div class=""
                                      style="line-height: 1.3;padding: 10px;">
                                      <router-link :to="'/videos/' + seg.sgiId + '/' + seg.sgiNomeSegmento.replaceAll(' ', '-').replaceAll('/', '').replaceAll(',', '')">
                                          <i class="fa fa-play" style="color:#000000;"></i>
                                          <span
                                              style="font-weight: 500; font-size: 16px;padding-left: 8px;color:#333333;">{{
                                                  seg.sgiNomeSegmento }}</span>
                                      </router-link>
                                  </div><!-- End .custom-checkbox -->
                              </div><!-- End .filter-item -->
                          </div><!-- End .widget-body -->
                      </div><!-- End .collapse -->
                  </div><!-- End .widget -->
              </div><!-- End .sidebar sidebar-shop -->
          </aside><!-- End .col-lg-3 -->
        </div>
      </div>
      <div class="container" v-if="vshowprod">
        <div class="row">
          <div class="col-lg-6 mb-5 mb-lg-0" v-for="vid in Videos" :key="vid.itiId" >
            <div class="blog_left_sidebar">
              <article class="blog_item" style="text-align: center;">
                <div class="blog_item_img">
                  <iframe width="100%" height="315" :src="vid.itiReferencia" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <p style="font-size: 20px;"><strong>{{ vid.itiNome }}</strong></p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'BlogView',
  setup() {
    const route = useRoute();
    const id = route.params.idSegmento;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    blogtopo: [],
    Videos: [],
    newb: [],
    SegmentoCatalogo:[],
    vshowprod:false,
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Vídeos - Corofergs Soluções em Usinagem'
      })
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    carregaTexto(value) {
      this.newb = this.blog.filter(x => x.itiId == value)
      if (this.isMobile()) {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Isso adicionará uma animação de rolagem suave
      });
    },
    isMobile() {
      return window.innerWidth <= 768; // Você pode ajustar esse valor de acordo com suas necessidades
    }
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/34/videostopo')
      .then(response => {
        this.blogtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

      if (this.id!='' && this.id!=null){
            axios.get('/Process/InsInstitucional/institucional/35/videos')
              .then(response => {
                this.Videos = response.data.filter(x => x.insInstitucionalSegmentos!= "" && x.insInstitucionalSegmentos.find(w=> w.insSegmentos.sgiId == this.id) && x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem).reverse();
                console.log('this.Videos')
                console.log(this.Videos)
                this.vshowprod =true;
              })
              .catch(error => {
                console.error(error);
              });
      }
      else{
        axios.get('/Process/InsSegmentos/GetAll')
            .then(response => {
                this.SegmentosInst = response.data;
                this.SegmentosMenuInst = this.SegmentosInst.filter(x => x.sgiIdPai == 0 && x.sgiVisivel).sort((a, b) => {
                    const nomeA = a.sgiNomeSegmento || "";
                    const nomeB = b.sgiNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });

                this.SegmentoCatalogo = this.SegmentosMenuInst.filter(x=> x.ctiId == 35).sort((a, b) => a.sgiOrdem - b.sgiOrdem);
                this.vshowprod =false;
            })
            .catch(error => {
                console.error(error);
            });
          }
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
