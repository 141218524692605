<template>
    <div>
        <main class="main">
            <div class="page-content pt-6">
                <div class="container">
                    <div class="product-details-top mb-2">
                        <div class="row" style="padding-bottom: 30px;">
                            <div class="col-md-6">
                                <div class="product-gallery product-gallery-vertical">
                                    <div class="row" v-if="ProdutoImg!=''">
                                        <v-carousel show-arrows="hover" hide-delimiters style="min-height: 300px !important;height: auto !important;">
                                            <v-carousel-item v-for="itemimg in ProdutoImg" :key="itemimg.pimId"
                                                :src="itemimg.pimUrlImagem" contain style="height: 300px;"
                                            ></v-carousel-item>
                                        </v-carousel>
                                        
                                    </div><!-- End .row -->
                                </div><!-- End .product-gallery -->
                            </div><!-- End .col-md-6 -->

                            <div class="col-md-6" v-if="Produto!=''">
                                <div class="product-details product-details-centered">
                                    <h1 class="product-title" style="color: #1696e7;margin-bottom: 0px !important;font-weight: 500;">{{Produto.proNomeProduto}} </h1>
                                    <div v-if="Produto.proReferencia!=''" style="font-size: 14px;color:#333333;">Ref: {{Produto.proReferencia}}</div><!-- End .product-title -->
                                    <div v-if="Produto.proTempoProducao!='' && Produto.proTempoProducao!=0" style="font-size: 14px;color:#333333;">EAN: {{Produto.proTempoProducao}}</div><!-- End .product-title -->
                                    <p><a :href="Produto.proCampoExtra10" target="_blank" style="color:#1696e7">Detalhes Técnicos</a></p>
                                    <div class="row">
                                        <div class="col-lg-12 pb-3" style="padding: 20px;">
                                            <p v-html="Produto.proTexto1"></p>
                                            <p v-html="Produto.proTexto2"></p>
                                        </div>
                                     </div>

                                    <div class="product-details-action" style="padding-top: 10px;">
                                        <div class="details-action-col">
                                            <a href="#" class="btn btn-product btn-cart"  @click="AdicionaCarrinho()">Adicionar Carrinho</a>
                                        </div><!-- End .details-action-col -->
                                    </div><!-- End .product-details-action -->
                                </div><!-- End .product-details -->
                            </div><!-- End .col-md-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .product-details-top -->
                </div><!-- End .container -->
                <div v-if="infocall!=''" class="cta bg-image bg-dark" :style="{backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom:'80px'}">
                    <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-sm-10 col-md-8 col-lg-6">
                        <div class="cta-text text-center">
                            <h3 class="cta-title text-white">{{infocall[0].itiNome}}</h3><!-- End .cta-title -->
                            <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1" style="color: #ccc !important;"></p><!-- End .cta-desc -->

                            <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded" target="_blank"><span>{{infocall[0].itiTextoBtn1}}</span><i
                                class="icon-long-arrow-right"></i></a>
                        </div><!-- End .cta-text -->
                        </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
                    </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .cta -->
            </div><!-- End .page-content -->
        </main>

    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
    name: 'ProdutosDetalheView',
    setup() {
        const route = useRoute();
        const id = route.params.idProduto;
        const nomeProduto = route.params.nomeProduto;
        return {
            id
        };
    },
    components: {
    },
    data: () => ({
        Produto: [],
        ProdutoImg:[],
        infocall:[],
        valorTotal:0,
        vProdutosExtras:[],
        ProdutoAvulsos:[],
        DadosCompra: {
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
    }),
    methods: {
        carregaMetas() {
            useHead({
                title: 'Produtos Detalhes - Corofergs Soluções em Usinagem'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        AdicionaAvulso(produtoAvulsoAdicionado){
           let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.avulsos.push(produtoAvulsoAdicionado.proProdutosAvulso);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.vProdutosExtras.push(produtoAvulsoAdicionado.proProdutosAvulso)
                this.calculatotal(this.Produto)
            }
        },
        ExcluiAvulso(index) {
        this.vProdutosExtras.splice(index, 1);
        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
       
        if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            dadosArmazenados.avulsos.splice(index, 1);
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
            this.calculatotal(this.Produto);
        }
       },
       AdicionaCarrinho(){
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.produtos.push(this.Produto);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.$router.push('/carrinho')
            }
       },
    },
    created() {
        this.carregaMetas();

        let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
        if (!dadosArmazenados) {
            dadosArmazenados = this.DadosCompra;
            localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados))
        }
        else {
            dadosArmazenados = JSON.parse(dadosArmazenados);

            if (dadosArmazenados.avulsos!=''){
                this.vProdutosExtras = dadosArmazenados.avulsos;
            }
        }

        axios.get('/Process/InsInstitucional/institucional/19/callaction')
            .then(response => {
                this.infocall = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        if (this.id != null && this.id != '') {
            axios.get('/Process/ProProdutos/GetById/' + this.id)
                .then(response => {
                    this.Produto = response.data;
                    if (this.Produto.proProdutosImagens!=''){
                        this.ProdutoImg = this.Produto.proProdutosImagens.filter(x=> x.pimVisivel).sort((a, b) => {
                                                                            const valorA = parseInt(a.pimOrdem) || 0;
                                                                            const valorB = parseInt(b.pimOrdem) || 0;
                                                                            return valorA - valorB;
                                                                            });

                      this.ProdutoImg.forEach(element => {
                        element.pimUrlImagem = this.$imgURL +  element.pimUrlImagem
                      });
                        
                    }
                    else{
                         if (this.Produto.proCampoExtra5!= ""){
                            this.ProdutoImg.push({pimUrlImagem: this.Produto.proCampoExtra5});
                         }
                         if (this.Produto.proCampoExtra7!= ""){
                            this.ProdutoImg.push({pimUrlImagem: this.Produto.proCampoExtra7});
                         }
                         if (this.Produto.proCampoExtra8!= ""){
                            this.ProdutoImg.push({pimUrlImagem: this.Produto.proCampoExtra8});
                         }
                         if (this.Produto.proCampoExtra9!= ""){
                            this.ProdutoImg.push({pimUrlImagem: this.Produto.proCampoExtra9});
                         }
                    }

                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/ProAvulsosporProdutos/GetAllByProId/' + this.id)
                .then(response => {
                    this.ProdutoAvulsos = response.data.filter(x=> x.proProdutosAvulso!=null && x.proProdutosAvulso.proProdutosAvulsosImagens!=null);
                    this.ProdutoAvulsos = this.ProdutoAvulsos.sort((a, b) => {
                                                                const valorA = parseInt(a.proProdutosAvulso.praOrdem) || 0;
                                                                const valorB = parseInt(b.proProdutosAvulso.praOrdem) || 0;
                                                                return valorA - valorB;
                                                                })
                    console.log('this.ProdutosAvulsos')
                    console.log(this.ProdutoAvulsos)
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
}
</script>

<style>.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}</style>
