<template>
  <div>
    <main>
      <div class="slider-area ">
            <div class="single-slider hero-overly slider-height2 d-flex align-items-center" v-if="sobretopo!='' && sobretopo[0].insInstitucionalImagens!=''" :style="{backgroundImage: 'url(' + $imgURL + sobretopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height:'300px'}">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap pt-100">
                                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{sobretopo[0].itiNome}}</h2>
                                <!--nav aria-label="breadcrumb ">
                                    <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Sobre</a></li> 
                                    </ol>
                                </!--nav-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


            <div class="page-content pb-3" style="padding-top: 50px;">
                <div class="container pb-6" v-if="sobreCestas!=''">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <div class="about-text text-center mt-3">
                                <h2 class="title text-center mb-2">{{sobreCestas[0].itiNome}}</h2><!-- End .title text-center mb-2 -->
                                <p v-html="sobreCestas[0].itiResumo1"></p><br>
                               
                            </div><!-- End .about-text -->
                        </div><!-- End .col-lg-10 offset-1 -->
                    </div><!-- End .row -->
                </div><!-- End .container -->

                <div class="container pb-6" v-if="sobreCestas!=''">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <div class="about-text text-center mt-3">
                                <h2 class="title text-center mb-2">{{sobreCestas[0].itiReferencia}}</h2><!-- End .title text-center mb-2 -->
                                <p v-html="sobreCestas[0].itiResumo2"></p><br>
                               
                            </div><!-- End .about-text -->
                        </div><!-- End .col-lg-10 offset-1 -->
                    </div><!-- End .row -->
                </div><!-- End .container -->

               <div class="mb-2"></div><!-- End .mb-2 -->
               <FooterDepoimentos/>
                <div v-if="numerosfundo!='' && numerosfundo[0].insInstitucionalImagens!=''" class="bg-image" style="padding-top: 60px; padding-bottom: 60px;">
                    <div class="container">
                      <div class="row">
                          <div class="col-xl-12">
                            <!-- Section Tittle -->
                            <div class="section-tittle section-tittle6 mb-50">
                              <div class="front-text">
                                <h2 class="" style="color:#000000;">Porque Corofergs!</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col-6 col-md-3" v-for="numero in numeros" :key="numero.itiId">
                                <div class="count-container text-center">
                                    <div class="count-wrapper" style="display:inline-flex;">
                                      <VueJsCounter start="0" :end="numero.itiNome" duration="8000" class="counter" style="font-size: 40px;font-weight: 300;line-height: 0.9;margin-bottom: 5px;color: #000000;"></VueJsCounter>{{ numero.itiCampoExtra1 }}
                                    </div><!-- End .count-wrapper -->
                                    <h3 class="count-title" style="font-size:20px;">{{numero.itiReferencia}}</h3><!-- End .count-title -->
                                </div><!-- End .count-container -->
                            </div><!-- End .col-6 col-md-3 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .bg-image pt-8 pb-8 -->

            </div><!-- End .page-content -->
    </main>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import VueJsCounter from 'vue-js-counter'
import { useHead } from '@vueuse/head';
import FooterDepoimentos from '@/components/PageDepoimentos.vue'

export default {
  name: 'SobreView',
  components: {
    VueJsCounter,
    FooterDepoimentos
  },
  data: () => ({
    sobretopo: [],
    sobreCestas:[],
    numeros:[],
    numerosfundo:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Corofergs Soluções em Usinagem'
			})
		},
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/11/sobretopo')
      .then(response => {
        this.sobretopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/12/sobre')
      .then(response => {
        this.sobreCestas = response.data;
      })
      .catch(error => {
        console.error(error);
      });

   axios.get('/Process/InsInstitucional/institucional/14/numeros')
      .then(response => {
        this.numeros = response.data.filter(x=> x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);
      })
      .catch(error => {
        console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/25/numerosimgfundo')
      .then(response => {
        this.numerosfundo = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
