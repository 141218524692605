<template>
  <div>
    <div class="slider-area ">
      <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
        v-if="contatotopo != '' && contatotopo[0].insInstitucionalImagens != ''"
        :style="{ backgroundImage: 'url(' + $imgURL + contatotopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap pt-100">
                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{ contatotopo[0].itiNome }}</h2>
                <!--nav aria-label="breadcrumb ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Contato</a></li>
                  </ol>
                </!--nav-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="contact-section" v-if="contato != ''" style="padding-bottom: 0px !important;padding-top: 80px;">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="contact-title">{{ contato[0].itiNome }}</h2>
            <p>{{ contato[0].itiReferencia }}</p>
          </div>
          <div class="col-lg-8">
            <form class="form-contact contact_form">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <textarea class="form-control w-100" v-model="Mensagem" name="message" id="message" cols="30" rows="9"
                      onfocus="this.placeholder = ''" onblur="this.placeholder = ' Mensagem'"
                      placeholder=" Mensagem"></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input class="form-control valid" v-model="Nome" name="name" id="name" type="text"
                      onfocus="this.placeholder = ''" onblur="this.placeholder = 'Nome'"
                      placeholder="Nome">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <input class="form-control valid" v-model="Email" name="email" id="email" type="email"
                      onfocus="this.placeholder = ''" onblur="this.placeholder = 'E-mail'"
                      placeholder="E-mail">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <input class="form-control" v-model="Telefone" name="subject" id="subject" type="text"
                      onfocus="this.placeholder = ''" onblur="this.placeholder = 'Telefone'"
                      placeholder="Telefone">
                  </div>
                </div>
              </div>
              <div class="form-group mt-3">
                <button @click.prevent="EnviaEmail()" class="button button-contactForm boxed-btn">Enviar</button>
              </div>
            </form>
          </div>
          <div class="col-lg-3 offset-lg-1">
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-home"></i></span>
              <div class="media-body">
                <h3>Endereço</h3>
                <p v-html="contato[0].itiResumo1"></p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-tablet"></i></span>
              <div class="media-body">
                <h3>Contatos</h3>
                <p v-html="contato[0].itiResumo2"></p>
              </div>
            </div>
            <div class="media contact-info">
              <span class="contact-info__icon"><i class="ti-world"></i></span>
              <div class="media-body">
                <h3 style="padding-bottom: 15px;">Corofergs nas Redes</h3>
                <ul class="header-social" style="display: inline-flex;">
                  <li style="padding: 5px;"><a v-if="redes[0].itiReferencia != '' && redes[0].itiReferencia != null"
                      :href="redes[0].itiReferencia" target="_blank"><i class="fab fa-instagram"
                        style="color: #000;font-size: 20px;"></i></a></li>
                  <li style="padding: 5px;"><a v-if="redes[0].itiNome != '' && redes[0].itiNome != null"
                      :href="redes[0].itiNome" target="_blank"><i class="fab fa-facebook"
                        style="color: #000;font-size: 20px;"></i></a></li>
                  <li style="padding: 5px;"><a v-if="redes[0].itiCampoExtra2 != '' && redes[0].itiCampoExtra2 != null"
                      :href="redes[0].itiCampoExtra2" target="_blank"><i class="fab fa-linkedin-in"
                        style="color: #000;font-size: 20px;"></i></a></li>
                  <li style="padding: 5px;"> <a v-if="redes[0].itiCampoExtra1 != '' && redes[0].itiCampoExtra1 != null"
                      :href="redes[0].itiCampoExtra1" target="_blank"><i class="fab fa-youtube"
                        style="color: #000;font-size: 20px;"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + contato[0].itiCampoExtra2"
        width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
      </iframe>
    </section>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'FaleConoscoView',
  components: {
  },
  data: () => ({
    contatotopo: [],
    contato: [],
    Nome: '',
    Email: '',
    Telefone: '',
    Assunto: '',
    Mensagem: '',
    redes: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Fale Conosco - Corofergs Soluções em Usinagem'
      })
    },
    EnviaEmail() {
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Corofergs Soluções em Usinagem",
          assunto: 'Contato do Site - ' + this.Assunto,
          destinatario: this.contato[0].itiCampoExtra1,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Assunto = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/15/contatotopo')
      .then(response => {
        this.contatotopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/16/contato')
      .then(response => {
        this.contato = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/20/redessociais')
      .then(response => {
        this.redes = response.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}
</style>
