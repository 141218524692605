<template>
  <main>
    <div class="slider-area ">
      <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
        v-if="blogtopo != '' && blogtopo[0].insInstitucionalImagens != ''"
        :style="{ backgroundImage: 'url(' + $imgURL + blogtopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap pt-100">
                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{ blogtopo[0].itiNome }}</h2>
                <!--nav aria-label="breadcrumb ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Treinamentos e Eventos</a></li>
                  </ol>
                </!--nav-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Blog section  -->
    <section class="blog_area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0" >
            <div class="blog_left_sidebar">
              <article class="blog_item" v-if="newb != ''">
                <div class="blog_item_img">
                  <img style="height:auto;width:100%" :src="$imgURL + newb[0].insInstitucionalImagens[0].itgUrlImagem" alt="">
                    <h3 class="blog_item_date">{{ formattedDate(newb[0].itiData1) }}</h3>
                </div>

                <div class="blog_details">
                    <h2 class="d-inline-block" style="padding-bottom: 20px;">{{ newb[0].itiNome }}</h2>

                    <p v-if="newb[0].itiReferencia!='' && newb[0].itiReferencia!=null"><strong>Valor: {{ newb[0].itiReferencia }}</strong></p>
                 <p v-if="newb[0].itiCampoExtra1!='' && newb[0].itiCampoExtra1!=null"><strong>Onde: {{ newb[0].itiCampoExtra1 }}</strong></p>
                 <p v-if="newb[0].itiCampoExtra2!='' && newb[0].itiCampoExtra2!=null"><strong>Duração: {{ newb[0].itiCampoExtra2 }}</strong></p>
                  <p v-html="newb[0].itiResumo1" style="padding-top: 20px;"></p>
                </div>
              </article>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget popular_post_widget">
                <h3 class="widget_title">Treinamentos e Eventos</h3>
                <div class="media post_item" v-for="menu in blog" :key="menu.itiId">
                  <a href="#" @click.prevent="carregaTexto(menu.itiId)">
                  <img style="height:60px;width:100px" :src="$imgURL + menu.insInstitucionalImagens[0].itgUrlImagem"
                      alt="">
                      </a>
                  <div class="media-body">
                    <a href="#" @click.prevent="carregaTexto(menu.itiId)">
                      <h3>{{ menu.itiNome }}</h3>
                    </a>
                    <p>{{ formattedDate(menu.itiData1) }}</p>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'TreinamentosEventosView',
  setup() {
    const route = useRoute();
    const id = route.params.idTreinamento;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    blogtopo: [],
    blog: [],
    newb: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Treinamentos e Eventos - Corofergs Soluções em Usinagem'
      })
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    carregaTexto(value) {
      this.newb = this.blog.filter(x => x.itiId == value)
      if (this.isMobile()) {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Isso adicionará uma animação de rolagem suave
      });
    },
    isMobile() {
      return window.innerWidth <= 768; // Você pode ajustar esse valor de acordo com suas necessidades
    }
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/32/treinamentostopo')
      .then(response => {
        this.blogtopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/33/treinamentoseventos')
      .then(response => {
        this.blog = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiData1 - b.itiData1).reverse();
        console.log('this.blog')
        console.log(this.blog)

        if (this.id) {
          this.carregaTexto(this.id);
        }
        else {
          this.carregaTexto(this.blog[0].itiId);
        }

      })
      .catch(error => {
        console.error(error);
      });


  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}</style>
