<template>
    <div>
        <main>
            <div class="slider-area ">
                <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
                    v-if="informacoestopo != '' && informacoestopo[0].insInstitucionalImagens != ''"
                    :style="{ backgroundImage: 'url(' + $imgURL + informacoestopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="hero-cap pt-100">
                                    <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">
                                        {{ informacoestopo[0].itiNome }}</h2>
                                    <!--nav aria-label="breadcrumb ">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item"><router-link to="/"
                                                    style="color:#000000;">Home</router-link></li>
                                            <li class="breadcrumb-item"><a href="#" style="color:#000000;">Produtos</a></li>
                                        </ol>
                                    </!--nav-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-content" style="padding-top: 60px;padding-bottom: 60px;">
                <div class="container">
                    <div class="row"  style="padding-bottom: 20px;">
                        <div class="col-lg-6 col-10">
                            <input type="search" class="form-control" style="width: 100%;" v-model="pesquisaChave" @keyup.enter="Pesquisa()" placeholder="Pesquise pelo termo que procura..." required>
                         </div>
                         <div class="col-lg-3 col-2" style="padding-top: 6px;padding-left: 0px;text-align: left;margin: 0;">
                             <button class="" @click="Pesquisa()"><i class="fa fa-search" style="color:#000000;font-size: 26px;"></i></button>
                         </div>
                        <aside v-if="!vshowprod" class="col-lg-12 mt-4">
                            <div class="sidebar sidebar-shop">
                                <div class="widget widget-collapsible">
                                    <h3 class="widget-title" style="padding-bottom: 20px;">
                                        Selecione uma Categoria {{ NomeCategoria }}
                                    </h3><!-- End .widget-title -->
                                    <div class="collapse show" id="widget-1">
                                        <div class="widget-body alinhaprod">
                                            <div class="" v-for="seg in SegmentosMenu" :key="seg.psgId"
                                                style="line-height: 2;">
                                                <div class=""
                                                    style="line-height: 1.3;padding: 10px;">
                                                    <a href="#"
                                                        @click.prevent="filtraProdutos(seg.psgNomeSegmento, seg.psgId, seg.psgIdPai)">
                                                        <i class="fa fa-cog" style="color:#000000;"></i>
                                                        <span
                                                            style="font-weight: 500; font-size: 16px;padding-left: 8px;color:#333333;">{{
                                                                seg.psgNomeSegmento }}</span>
                                                    </a>
                                                </div><!-- End .custom-checkbox -->
                                            </div><!-- End .filter-item -->
                                        </div><!-- End .widget-body -->
                                    </div><!-- End .collapse -->
                                </div><!-- End .widget -->
                            </div><!-- End .sidebar sidebar-shop -->
                        </aside><!-- End .col-lg-3 -->
                        </div>
                    <div class="row" v-if="vshowprod">
                        <aside class="col-lg-3">
                            <div class="sidebar sidebar-shop">
                                <div class="widget widget-collapsible">
                                    <h3 class="widget-title">
                                        Categorias
                                    </h3><!-- End .widget-title -->
                                    <router-link v-if="$route.fullPath.includes('ProdutosPesquisa')"  to="/produtos/0/Marcas" class="btn btn-info" style="color:#FFF">Voltar</router-link>
                                    <div class="collapse show" id="widget-1">
                                        <div class="widget-body">
                                            <div class="" v-for="seg in SegmentosMenu" :key="seg.psgId"
                                                style="line-height: 2;">
                                                <div class=""
                                                    style="line-height: 1.3; border: 1px solid #cccccc;padding: 10px;">
                                                    <a href="#"
                                                        @click.prevent="filtraProdutos(seg.psgNomeSegmento, seg.psgId, seg.psgIdPai)">
                                                        <i class="fa fa-cog" style="color:#000000;"></i>
                                                        <span
                                                            style="font-weight: 500; font-size: 16px;padding-left: 8px;color:#333333;">{{
                                                                seg.psgNomeSegmento }}</span>
                                                    </a>
                                                </div><!-- End .custom-checkbox -->
                                            </div><!-- End .filter-item -->
                                        </div><!-- End .widget-body -->
                                    </div><!-- End .collapse -->
                                </div><!-- End .widget -->
                            </div><!-- End .sidebar sidebar-shop -->
                        </aside><!-- End .col-lg-3 -->
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="toolbox-info" style="color:#000;font-size: 20px;font-weight: 500;">
                                        {{ NomeCategoria }}
                                    </div><!-- End .toolbox-info -->
                                </div>
                                <div class="col-lg-8" v-if="ProdutosFiltro != ''">
                                    <v-text-field v-model="searchText" label="Pesquisar na lista" underlined></v-text-field>
                                </div>
                                <div class="col-lg-4" v-if="ProdutosFiltro != ''">
                                    <v-select v-model="Ordenacao" :items="OrdenacaoAtivo" label="Ordenar por"
                                        @update:modelValue="OrdenaProdutos(Ordenacao)" item-title="nome" item-value="valor"
                                        variant="underlined" style="width: 180px;">
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <!-- Nav Card -->
                                <div class="tab-content active" id="nav-tabContent">
                                    <!-- card ONE -->
                                    <div class="tab-pane fade active show">
                                        <div class="project-caption" v-if="ProdutosFiltro != ''">
                                            <div class="row mb-3" v-for="(prod4car, index) in filteredProdutos"
                                                :key="prod4car.proProdutos.proId"
                                                :class="index % 2 === 0 ? 'white-row' : 'light-gray-row'">
                                                <div class="col-lg-2 col-md-6 d-flex align-items-center justify-content-center"
                                                    style="padding: 15px;">
                                                    <a v-if="prod4car.proProdutos.proTexto4 != null" href="#"
                                                        @click.prevent="show(prod4car.proProdutos)">
                                                        <img :src="$imgURL + prod4car.proProdutos.proTexto4" alt="" style="width: 80px;">
                                                    </a>
                                                    <a v-else href="#" @click.prevent="show(prod4car.proProdutos)">
                                                        <img :src="prod4car.proProdutos.proCampoExtra7" alt=""
                                                            style="width: 60px;">
                                                    </a>
                                                    <br>
                                                </div>
                                                <div class="col-lg-3 col-md-6 mt-2">
                                                    <span v-if="prod4car.proProdutos.proReferencia!=''">Ref:</span> {{ prod4car.proProdutos.proReferencia }} <span v-if="prod4car.proProdutos.proReferencia!=''"> - </span> 
                                                    {{ prod4car.proProdutos.proNomeProduto }}
                                                    <p v-if="prod4car.proProdutos.proTempoProducao!=0">EAN: {{ prod4car.proProdutos.proTempoProducao }}</p>
                                                    <p v-html="prod4car.proProdutos.proTexto1"></p>
                                                    <p><a :href="prod4car.proProdutos.proCampoExtra10" target="_blank"
                                                            style="color:#1696e7">Detalhes Técnicos</a></p>
                                                </div>
                                                <div class="col-lg-5 col-md-6 txttam">
                                                    <p v-html="prod4car.proProdutos.proTexto2"></p>
                                                </div>
                                                <div class="col-lg-2 col-md-6 align-items-center justify-content-center">
                                                    <button class="btn btn-small"
                                                        @click.prevent="AdicionaCarrinho(prod4car.proProdutos)">Add
                                                        Carrinho</button>
                                                    <router-link class="btn btn-small" v-if="prod4car.proProdutos.proTexto2!=''"
                                                        style="background-color: #1696e7;margin-top: 10px;"
                                                        :to="AjustaUrl('/produtosdetalhe/' + prod4car.proProdutos.proId, prod4car.proProdutos.proNomeProduto)">Ver
                                                        Detalhes</router-link>
                                                </div>
                                            </div>
                                            <div>
                                                <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
                                                <span
                                                    style="padding-left: 10px;padding-right: 10px;font-weight: 500;">Página
                                                    {{ currentPage }}</span>
                                                <button @click="nextPage"
                                                    :disabled="currentPage * itemsPerPage >= ProdutosFiltro.length">Próxima</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Nav Card -->
                            </div>

                        </div><!-- End .col-lg-9 -->

                    </div><!-- End .row -->
                </div><!-- End .container -->
                <div class="images" v-viewer>
                    <img v-for="src in images" :key="src" :src="src">
                </div>
                <div v-if="infocall != ''" class="cta bg-image bg-dark"
                    :style="{ backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom: '80px' }">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-sm-10 col-md-8 col-lg-6">
                                <div class="cta-text text-center">
                                    <h3 class="cta-title text-white">{{ infocall[0].itiNome }}</h3><!-- End .cta-title -->
                                    <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1"
                                        style="color: #ccc !important;"></p><!-- End .cta-desc -->

                                    <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded"
                                        target="_blank"><span>{{ infocall[0].itiTextoBtn1 }}</span><i
                                            class="icon-long-arrow-right"></i></a>
                                </div><!-- End .cta-text -->
                            </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
                        </div><!-- End .row -->
                    </div><!-- End .container -->
                </div><!-- End .cta -->
            </div><!-- End .page-content -->
        </main>

    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

export default {
    name: 'ProdutosView',
    setup() {
        const route = useRoute();
        const id = route.params.idSegmentoProduto;
        const nomeSeg = route.params.nomeSegmento;
        const pesquisa = route.params.chavepesquisa;

        return {
            id, pesquisa
        };
    },
    components: {
        Viewer,
    },
    data: () => ({
        informacoestopo: [],
        produtosSegmentos: [],
        Segmentos: [],
        SegmentosMenu: [],
        ProdutosGeral: [],
        ProdutosFiltro: [],
        infocall: [],
        CategoriaClicada: false,
        NomeCategoria: '',
        OrdenacaoAtivo: [{ nome: "A-Z", valor: 1 }, { nome: "Z-A", valor: 2 }],
        Ordenacao: '',
        searchText: '',
        activeIndex: 0, // Índice inicial da imagem ativa na galeria
        imagesForGallery: [], // Array de imagens para a galeria
        viewerOptions: {},
        idpai: 0,
        currentPage: 1,
        itemsPerPage: 10,
        vshowprod:false,
    }),
    computed: {
        filteredProdutos() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            // Filtrar os produtos com base no texto da pesquisa
            return this.ProdutosFiltro.filter(produto =>
                produto.proProdutos.proTexto2.toLowerCase().includes(this.searchText.toLowerCase()) ||
                produto.proProdutos.proTexto1.toLowerCase().includes(this.searchText.toLowerCase()) ||
                produto.proProdutos.proNomeProduto.toLowerCase().includes(this.searchText.toLowerCase()) ||
                produto.proProdutos.proReferencia.toLowerCase().includes(this.searchText.toLowerCase())
                // Ajuste 'produto.nome' para o campo que deseja pesquisar
            ).slice(startIndex, endIndex);
        }
    },
    methods: {
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;
            var vcount = 0;
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);

                if (dadosArmazenados.avulsos != '') {

                    dadosArmazenados.avulsos.forEach(item => {
                       // valorAvulsos += item.praValorPor;
                        vcount++;
                    });
                }

                if (dadosArmazenados.produtos != '') {

                    dadosArmazenados.produtos.forEach(item => {
                     //   valorProdutos += item.proProdutosPrecos[0].ppcValorPor;
                        vcount++;
                    });
                }
               // this.valorTotal = (valorProdutos + valorAvulsos)- this.ValorCupom;
                this.$store.commit('updateVariavelHeader2', vcount);
            }
            else{
                this.$store.commit('updateVariavelHeader2', '');
            }
           
        },
        Pesquisa() {
            if (this.pesquisaChave == "") {
                this.$mensagemAviso("Digite uma palavra chave para pesquisa.")
            }
            else {
                console.log("Método Pesquisa chamado");
                let pesquisa = this.pesquisaChave.replaceAll("/","").replaceAll("-","")
                const novaURL = `/ProdutosPesquisa/${pesquisa}`;
                console.log(novaURL)
                this.$router.push(novaURL);
            }
        },
        show(produtoimgs) {
            console.log('produtoimgs')
            console.log(produtoimgs)
            this.imagesForGallery = [];
            if (produtoimgs.proTexto4 == '' || produtoimgs.proTexto4 == null) {
                this.imagesForGallery.push({ src: produtoimgs.proCampoExtra5 }, { src: produtoimgs.proCampoExtra7 }, { src: produtoimgs.proCampoExtra8 }, { src: produtoimgs.proCampoExtra9 })
            }
            else {
                this.imagesForGallery.push({ src: this.$imgURL + produtoimgs.proTexto4 })
            }

            this.$viewerApi({
                images: this.imagesForGallery,
            })
        },
        AdicionaCarrinho(produto) {
            let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                dadosArmazenados.produtos.push(produto);
                localStorage.setItem(this.$DadosCompra, JSON.stringify(dadosArmazenados));
                this.$mensagemSucesso("Produto adicionado ao carrinho.")
                this.calculatotal();
            }
        },
        carregaMetas() {
            useHead({
                title: 'Produtos - Corofergs Soluções em Usinagem'
            })
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        carregaTexto(value) {
            this.informacao = this.informacoes.filter(x => x.itiId == value)
        },
        OrdenaProdutos(value) {
            if (value == 1) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                });
            }
            else if (value == 2) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const nomeA = a.proProdutos.proNomeProduto || "";
                    const nomeB = b.proProdutos.proNomeProduto || "";
                    return nomeA.localeCompare(nomeB);
                }).reverse();
            }
            else if (value == 3) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                }).reverse();
            }
            else if (value == 4) {
                this.ProdutosFiltro = this.ProdutosFiltro.sort((a, b) => {
                    const valorA = parseInt(a.proProdutos.proValorPor) || 0;
                    const valorB = parseInt(b.proProdutos.proValorPor) || 0;
                    return valorA - valorB;
                });
            }

        },
        filtraProdutos(nomeSegmento, psgId, psgPaiId) {
            if (psgPaiId!='' && psgPaiId!=null){
            axios.get('/Process/ProProdutosSegmentos/GetAllBySiteBySegPai/' + psgId)
                .then(response => {
                    //console.log(response.data)
                    this.ProdutosFiltro = response.data;
                    console.log('this.ProdutosFiltro2')
                    console.log(this.ProdutosFiltro)
                })
                .catch(error => {
                    console.error(error);
                });

            this.NomeCategoria = nomeSegmento;
            this.currentPage = 1;
           
                this.vshowprod = true;
            }
            else{
                this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == psgId && x.psgVisivel).sort((a, b) => {
                    const nomeA = a.psgNomeSegmento || "";
                    const nomeB = b.psgNomeSegmento || "";
                    return nomeA.localeCompare(nomeB);
                });

                console.log('SegmentosMenu')
                console.log(this.SegmentosMenu)

                this.NomeCategoria = this.Segmentos.filter(x=> x.psgId == psgId)[0].psgNomeSegmento;
                this.vshowprod = false;
            }
            
        },
        AjustaUrl(value, nome) {
            let urlFriendlyNomeProduto = nome
                .normalize("NFD") // Normaliza para decompor os caracteres acentuados
                .replace(/[\u0300-\u036f]/g, "") // Remove os caracteres acentuados
                .replace(/[^\w\s-]/g, "") // Remove caracteres especiais
                .replace(/\s+/g, "-") // Substitui espaços em branco por hífens
                .toLowerCase(); // Converte para minúsculas

            return `${value}/${urlFriendlyNomeProduto}`;
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
            this.scrollToTop();
        },
        nextPage() {
            const totalPages = Math.ceil(this.ProdutosFiltro.length / this.itemsPerPage);
            if (this.currentPage < totalPages) {
                this.currentPage++;
            }
            this.scrollToTop();
        },
        scrollToTop() {
            this.scrollTo(300, 1500); // 0 é a posição para onde você deseja rolar e 500 é a duração da animação em milissegundos
        },
        scrollTo(to, duration) {
            const start = window.scrollY;
            const change = to - start;
            const increment = 20;
            let currentTime = 0;

            const animateScroll = () => {
                currentTime += increment;
                const val = this.easeInOutQuad(currentTime, start, change, duration);
                window.scrollTo(0, val);
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                }
            };

            animateScroll();
        },
        easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        },

    },
    async created() {
        this.carregaMetas();

        axios.get('/Process/InsInstitucional/institucional/27/imagemtopo')
            .then(response => {
                this.informacoestopo = response.data;
            })
            .catch(error => {
                console.error(error);
            });

        axios.get('/Process/InsInstitucional/institucional/19/callaction')
            .then(response => {
                this.infocall = response.data;
            })
            .catch(error => {
                console.error(error);
            });

            if (this.pesquisa != null && this.pesquisa != '') {

                let _pesquisa = this.pesquisa.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

                axios.get('/Process/ProProdutosSegmentos/GetAllBySiteBySearch/' + _pesquisa)
                .then(response => {
                    //console.log(response.data)
                    this.ProdutosFiltro = response.data;
                    console.log('this.ProdutosFiltro2')
                    console.log(this.ProdutosFiltro)
                })
                .catch(error => {
                    console.error(error);
                });

                this.NomeCategoria = this.pesquisa;
                this.vshowprod = true;
            }
            else if (this.id!= 0){
                await axios.get('/Process/ProSegmentos/GetAll')
                    .then(response => {
                        this.Segmentos = response.data;
                        this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == this.id && x.psgVisivel).sort((a, b) => {
                            const nomeA = a.psgNomeSegmento || "";
                            const nomeB = b.psgNomeSegmento || "";
                            return nomeA.localeCompare(nomeB);
                        });

                        this.NomeCategoria = this.Segmentos.filter(x=> x.psgId == this.id)[0].psgNomeSegmento;
                    })
                    .catch(error => {
                        console.error(error);
                    });
        }
        else{
            await axios.get('/Process/ProSegmentos/GetAll')
                    .then(response => {
                        this.Segmentos = response.data;
                        this.SegmentosMenu = this.Segmentos.filter(x => x.psgIdPai == '' && x.psgVisivel).sort((a, b) => {
                            const nomeA = a.psgNomeSegmento || "";
                            const nomeB = b.psgNomeSegmento || "";
                            return nomeA.localeCompare(nomeB);
                        });

                        this.NomeCategoria = "";
                    })
                    .catch(error => {
                        console.error(error);
                    });
        }
    },
}
</script>

<style>

@media (max-width:767px) {

    .alinhaprod {
    display: grid;
    grid-template-columns:repeat(2,1fr);
}

}

@media (min-width:992px) and (max-width:1199px) {

    .alinhaprod {
    display: grid;
    grid-template-columns:repeat(3,1fr);
}

}


@media (min-width:1200px) {

    .alinhaprod {
    display: grid;
    grid-template-columns:repeat(3,1fr);
}

}

.hhss {
    display: none;
}

.sli p {
    color: #ede3e3 !important;
}

.txttam p {
    font-size: 12px;
}

.btn-small {
    /* Estilos específicos para o botão pequeno */
    padding: 15px 8px;
    font-size: 12px;
}

.white-row {
    background-color: #fff;
    /* Cor de fundo branca */
    /* Outros estilos para a linha branca */
}

.light-gray-row {
    background-color: #f5f5f5;
    /* Cor de fundo cinza claro */
    /* Outros estilos para a linha cinza claro */
}</style>
