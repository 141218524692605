<template>
    <div>
        <CheckAcesso />
        <main class="main">
            <section class="main-minha_conta">
                <div class="container">
                    <div class="row">
                        <!-- Sidenav -->
                        <div class="col-lg-2">
                            <ul class="lista-sidebar_links">
                                <li><router-link to="/meusDados" class="texto">Meus Dados</router-link></li>
                                <li><router-link to="/meusPedidos" class="texto active">Meus Pedidos</router-link></li>
                            </ul>
                        </div>
                        <!-- Main -->
                        <div class="col-lg-10" v-if="PedidosCli != ''">
                            <h3 class="titulo-principal text-center mb-4">Minha Conta</h3>
                            <h6 class="subtitulo h5 mb-4">Meu Pedido</h6>
                            <!-- Lista de Pedidos -->
                            <div class="row">
                                <div class="col-lg-12">
                                    <ul class="lista-pedidos border-top py-3">
                                        <li>
                                            <div class="row">
                                                <div class="col-6 col-md-3 col-lg-4 mb-4 mb-md-0">
                                                    <div class="pedido_id">
                                                        <i class="fas fa-clipboard-list text-success fa-2x"></i>
                                                        <div>
                                                            <h6 class="mb-0">Cotação: {{ PedidosCli[0].pedId }}</h6>
                                                            <strong>Situação:</strong> {{ PedidosCli[0].pedSituacaoPedido }}
                                                            <p><strong>Data:</strong> {{
                                                                formattedDate(PedidosCli[0].pedDataPedido) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-2 col-lg-2 mb-4 mb-md-0 d-flex align-items-center">
                                                    <div>
                                                        <div class="pedido_dados">R$ {{
                                                            parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',')
                                                        }}</div>
                                                    </div>
                                                </div>
                                                <!--div class="col-6 col-md-5 col-lg-5 mb-4 mb-md-0 d-flex align-items-center" v-if=" PedidosCli[0].pedSituacaoPedido == 'Orçado'">
                                            <button  class="btn mt-5" @click="PutSituacao()">
                                                Aprovar Orçamento
                                            </button>
                                          </!--div-->
                                                <div class="col-6 col-md-2 col-lg-4 mb-4 mb-md-0 d-flex align-items-center"
                                                    v-if="PedidoComprovante != ''">
                                                    <a :href="$imgURL + PedidoComprovante[0].ppcUrlImagem" target="blank"><i
                                                            class="fa fa-file" style="font-size: 20px;"></i> Ver
                                                        Comprovante</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- Status -->
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="status border-top py-3 px-xl-5">
                                        <div class="row">
                                            <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                                <i v-if="PedidosCli[0].pspId >= 1"
                                                    class="fa fa-thumbs-up text-success fa-2x"></i>
                                                <i v-else-if="PedidosCli[0].pspId <= 1"
                                                    class="fa fa-thumbs-up text-secondary2 fa-2x"></i>
                                                <p v-if="PedidosCli[0].pspId >= 1"
                                                    class="titulo-status text-success mb-0 mt-2"
                                                    style="font-size: 13px;color:#333">Em Atendimento</p>
                                                <p v-else-if="PedidosCli[0].pspId <= 1"
                                                    class="titulo-status text-secondary2 mb-0 mt-2"
                                                    style="font-size: 13px;color:#333">Em Atendimento</p>

                                            </div>
                                            <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                                <i v-if="PedidosCli[0].pspId >= 2"
                                                    class="fa fa-calendar text-success fa-2x"></i>
                                                <i v-else-if="PedidosCli[0].pspId <= 2"
                                                    class="fa fa-calendar text-secondary2 fa-2x"></i>
                                                <p v-if="PedidosCli[0].pspId >= 2"
                                                    class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">
                                                    Orçado</p>
                                                <p v-else-if="PedidosCli[0].pspId <= 2"
                                                    class="titulo-status text-secondary2 mb-0 mt-2"
                                                    style="font-size: 13px;">Orçado</p>
                                            </div>
                                            <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                                <i v-if="PedidosCli[0].pspId >= 3"
                                                    class="fa fa-money text-success fa-2x"></i>
                                                <i v-else-if="PedidosCli[0].pspId <= 3"
                                                    class="fa fa-money text-secondary2 fa-2x"></i>
                                                <p v-if="PedidosCli[0].pspId >= 3"
                                                    class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">
                                                    Aprovado</p>
                                                <p v-else-if="PedidosCli[0].pspId <= 3"
                                                    class="titulo-status text-secondary2 mb-0 mt-2"
                                                    style="font-size: 13px;">Aprovado</p>
                                            </div>

                                            <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                                <i v-if="PedidosCli[0].pspId >= 4"
                                                    class="fa fa-space-shuttle text-success fa-2x"></i>
                                                <i v-else-if="PedidosCli[0].pspId <= 4"
                                                    class="fa fa-space-shuttle text-secondary2 fa-2x"></i>
                                                <p v-if="PedidosCli[0].pspId >= 4"
                                                    class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">
                                                    Despachado</p>
                                                <p v-else-if="PedidosCli[0].pspId <= 4"
                                                    class="titulo-status text-secondary2 mb-0 mt-2"
                                                    style="font-size: 13px;">Despachado</p>
                                            </div>
                                            <div class="col-6 col-md-2 text-center mb-4 mb-md-0">
                                                <i v-if="PedidosCli[0].pspId >= 5"
                                                    class="fa fa-check text-success fa-2x"></i>
                                                <i v-else-if="PedidosCli[0].pspId <= 5"
                                                    class="fa fa-check text-secondary2 fa-2x"></i>
                                                <p v-if="PedidosCli[0].pspId >= 5"
                                                    class="titulo-status text-success mb-0 mt-2" style="font-size: 13px;">
                                                    Entregue</p>
                                                <p v-else-if="PedidosCli[0].pspId <= 5"
                                                    class="titulo-status text-secondary2 mb-0 mt-2"
                                                    style="font-size: 13px;">Entregue</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div v-for="produto in ListaPedidoItem" :key="produto.pdiId"
                                        class="descricao-pedido border-top py-3">
                                        <div class="row">
                                            <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                <div class="descricao-img">
                                                    <img v-if="produto.proProdutos.proCampoExtra10 != '' && produto.proProdutos.proCampoExtra10 != null"
                                                        class="img-fluid border border-rounded mb-3 mb-md-0"
                                                        :src="$imgURL + produto.proProdutos.proCampoExtra10" alt="">
                                                    <img v-else-if="produto.proProdutos.proCampoExtra7 != '' && produto.proProdutos.proCampoExtra7 != null"
                                                        class="img-fluid border border-rounded mb-3 mb-md-0"
                                                        :src="produto.proProdutos.proCampoExtra7" alt="">
                                                    <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                                        :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-2 d-flex align-items-center">
                                                <div class="descricao-content">
                                                    <h6 class="subtitulo">{{ produto.pdiNomeProduto }}</h6>
                                                    <p class="texto-mensagem"> Código: {{ produto.proId }}</p>
                                                    <p class="texto-mensagem"> Referência: {{
                                                        produto.proProdutos.proReferencia }} </p>
                                                    <p>EAN: {{ produto.proProdutos.proTempoProducao }}</p>
                                                    <p v-html="produto.proProdutos.proTexto1"></p>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3 d-flex align-items-center">
                                                <div class="descricao-content">
                                                    <p class="texto-mensagem txttam" v-html="produto.proProdutos.proTexto2">
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-1 d-flex align-items-center">
                                                <div class="descricao-content">
                                                    <small>Qtde</small>
                                                    <h6 class="subtitulo">{{ produto.pdiQtdeProduto }}</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-2 d-flex align-items-center">
                                                <div class="descricao-content">
                                                    <small>Valor</small>
                                                    <h6 class="subtitulo">R$ {{
                                                        parseFloat(produto.pdiValorProduto).toFixed(2).toString().replace('.', ',')
                                                    }}</h6>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-2 d-flex align-items-center">
                                                <div class="descricao-content">
                                                    <small>Valor Total</small>
                                                    <h6 class="subtitulo">R$ {{
                                                        parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                    }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="adicional in ListaPedidoItemAdicional" :key="adicional.piaId"
                                class="descricao-pedido border-top py-3">
                                <div class="row">
                                    <div class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                        <div class="descricao-img">
                                            <img v-if="adicional.proProdutosAvulsos.proProdutosAvulsosImagens != null"
                                                class="img-fluid border border-rounded mb-3 mb-md-0" style="width:80%;"
                                                :src="$imgURL + adicional.proProdutosAvulsos.proProdutosAvulsosImagens[0].paiUrlImagem"
                                                alt="">
                                            <img v-else class="img-fluid border border-rounded mb-3 mb-md-0"
                                                style="width:70%;" :src="$imgURL + '/imagens/indisponivel.jpg'" alt="">
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                        <div class="descricao-content">
                                            <h6 class="subtitulo">{{ adicional.piaNomeProduto }}</h6>
                                            <p class="texto-mensagem"> Código: {{
                                                adicional.proProdutosAvulsos.praReferencia }}</p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-3 d-flex align-items-center">
                                        <div class="descricao-content">
                                            <p class="texto-mensagem"></p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-1 d-flex align-items-center">
                                        <div class="descricao-content">
                                            <h6 class="subtitulo">{{ adicional.piaQtdeProduto }}</h6>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                        <div class="descricao-content">
                                            <h6 class="subtitulo">R$ {{
                                                parseFloat(adicional.piaValorProduto).toFixed(2).toString().replace('.', ',')
                                            }}</h6>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-2 d-flex align-items-center">
                                        <div class="descricao-content">
                                            <h6 class="subtitulo">R$ {{
                                                parseFloat(adicional.piaValorTotalProduto).toFixed(2).toString().replace('.', ',')
                                            }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="PedidosMensagem != ''">
                                <div v-for="pedidomensagem in PedidosMensagem" :key="pedidomensagem.pmsId" class="row">
                                    <div v-if="pedidomensagem.pmsTexto != ''" class="col">
                                        <div class="descricao-pedido border-top py-3">
                                            <div class="row">
                                                <div class="col-4 col-md-6">
                                                    <div class="descricao-img"
                                                        style="padding: 20px;border: 1px solid #cccccc;height:150px;overflow-y: auto;">
                                                        <p v-html="pedidomensagem.pmsTexto"></p>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-3 align-items-center">
                                                    <div class="descricao-content">
                                                        <small>Quem envia</small><br>
                                                        {{ pedidomensagem.pmsNomeDe }}
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-3 align-items-center">
                                                    <div class="descricao-content">
                                                        <small>Quem Recebe</small><br>
                                                        {{ pedidomensagem.pmsNomePara }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="PedidosEndereco != ''">
                                <div class="col-lg-12">
                                    <div class="border-top py-3">
                                        <div class="row">
                                            <div class="col-lg-7 mb-4 mb-lg-0">
                                                <div>
                                                    <h5 class="subtitulo">Entrega</h5>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Tipo
                                                            Frete:</strong> {{ PedidosCli[0].pedTipoFrete }} - {{
                                                                PedidosCli[0].pedObsLoja }}</p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;">
                                                        <strong>Endereço:</strong> {{ PedidosEndereco[0].pdcEndereco }}, {{
                                                            PedidosEndereco[0].pdcNumero }} - {{
        PedidosEndereco[0].pdcComplemento }} </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;">
                                                        <strong>Cidade/UF:</strong> {{ PedidosEndereco[0].pdcCidade }} / {{
                                                            PedidosEndereco[0].pdcEstado }}</p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;">
                                                        <strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro == '' ?
                                                            Bairros.filter(x => x.baiId == PedidosEndereco[0].baiId)[0].baiNome :
                                                            PedidosEndereco[0].pdcBairro }} </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;">
                                                        <strong>CEP:</strong> {{ PedidosEndereco[0].pdcCep }} </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;"><strong>Obs
                                                            Cliente:</strong> {{ PedidosCli[0].pedObsCliente }} </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">Desconto</h5>
                                                    <h6 class="subtitulo">(-) R$ {{
                                                        parseFloat(PedidosCli[0].pedValorCupom).toFixed(2).toString().replace('.', ',')
                                                    }}</h6>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">Frete</h5>
                                                    <h6 class="subtitulo">R$ {{
                                                        parseFloat(PedidosCli[0].pedValorFrete).toFixed(2).toString().replace('.', ',')
                                                    }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="border-top py-3">
                                        <div class="row">
                                            <div class="col-lg-2 offset-lg-7">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">Total</h5>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 offset-lg-1">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">R$ {{
                                                        parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',') }}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-2 offset-lg-7">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">Forma Pagamento:</h5>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 offset-lg-1">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <h5 class="subtitulo">{{ PedidosCli[0].pedFormaPgto }}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" style="padding-top: 50px;">
                                            <div class="col text-center">
                                                <router-link to="/meusPedidos" class="btn mt-5">Ver histórico de
                                                    cotações</router-link>
                                            </div>
                                            <div class="col text-center" v-if="PedidosCli[0].pedSituacaoPedido == 'Orçado'">
                                                <button class="btn mt-5" @click="PutSituacao()">
                                                    Aprovar Orçamento
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import moment from 'moment';
import CheckAcesso from '../components/PageCheckAcesso.vue'
import Cookies from 'js-cookie';
import { useRoute } from 'vue-router';

export default {
    name: 'MeusPedidosDetalheView',
    setup() {
        const route = useRoute();
        const id = route.params.idPedido;
        return {
            id
        };
    },
    components: {
        CheckAcesso,
    },
    data: () => ({
        PedidosCli: [],
        TotalPedido: 0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova: false,
        ListaPedidoItem: [],
        ListaPedidoItemAdicional: [],
        PedidosMensagem: [],
        PedidosAgendamento: [],
        PedidosEndereco: [],
        Estados: [],
        Cidades: [],
        Bairros: [],
        selectedFile: '',
        urlComprovante: '',
        PedidoComprovante: [],
        Situacoes: [],
        logintopo: [],
    }),
    methods: {
        async PutSituacao() {
            var _situacao = await this.Situacoes.filter(x => x.pspId == 3)[0].pspSituacao;
            await axios.put(`/Process/PedPedidos/UpdateSituacao/` + this.PedidosCli[0].pedId + '/3/' + _situacao)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Pedido Aprovado.")
                        this.EnviaAviso(_situacao);

                    }
                });
            //enviar email
        },
        async EnviaAviso(_situacao) {
            let dadosPedidos = "<h2><strong>Dados do Pedido</strong></h2><br/><br/>";

            dadosPedidos += " <strong>Cotação: </strong>" + this.PedidosCli[0].pedId + "<br/>";
            dadosPedidos += " <strong>Situação: </strong>" + _situacao + "<br/>";
            dadosPedidos += " <strong>Data: </strong>" + this.formattedDate(this.PedidosCli[0].pedDataPedido) + "<br/><br/>";

            dadosPedidos += " <strong>Entrega: </strong><br/><br/>";
            dadosPedidos += " <strong>Tipo Frete: </strong>" + this.PedidosCli[0].pedTipoFrete + " - " + this.PedidosCli[0].pedObsLoja + "<br/>";;
            dadosPedidos += " <strong>Endereço: </strong>" + this.PedidosEndereco[0].pdcEndereco + " - " + this.PedidosEndereco[0].pdcNumero + " - " + this.PedidosEndereco[0].pdcComplemento + "<br/>";
            dadosPedidos += " <strong>Cidade/UF: </strong>" + this.PedidosEndereco[0].pdcCidade + " - " + this.PedidosEndereco[0].pdcEstado + "<br/>";
            dadosPedidos += " <strong>Bairro: </strong>" + this.PedidosEndereco[0].pdcBairro == '' ? this.Bairros.filter(x => x.baiId == this.PedidosEndereco[0].baiId)[0].baiNome : this.PedidosEndereco[0].pdcBairro + " - " + this.PedidosEndereco[0].pdcEstado + "<br/>";
            dadosPedidos += " <strong>CEP: </strong>" + this.PedidosEndereco[0].pdcCep + "<br/>";
            dadosPedidos += " <strong>Obs Cliente: </strong>" + this.PedidosCli[0].pedObsCliente + "<br/><br/><br/>";


            dadosPedidos += " <strong>Itens do Pedido:</strong><br><br>"
            this.ListaPedidoItem.forEach(element => {
                dadosPedidos += "<strong>Nome: </strong>" + element.pdiNomeProduto + " | "
                    + "<strong>Referência: </strong>" + element.proProdutos.proReferencia + " | "
                    + "<strong>Qtde: </strong>" + element.pdiQtdeProduto + " | "
                    + "<strong>Valor: </strong> R$ " + parseFloat(element.pdiValorProduto).toFixed(2).toString().replace('.', ',') + " | "
                    + "<strong>Total: </strong> R$ " + parseFloat(element.pdiValorTotalProduto).toFixed(2).toString().replace('.', ',') + " | <br><br>";
            });


            dadosPedidos += " <strong>Desconto: </strong> R$ " + parseFloat(this.PedidosCli[0].pedValorCupom).toFixed(2).toString().replace('.', ',') + "<br/>";
            dadosPedidos += " <strong>Frete: </strong> R$ " + parseFloat(this.PedidosCli[0].pedValorFrete).toFixed(2).toString().replace('.', ',') + "<br/>";
            dadosPedidos += " <strong>Forma de Pgto: </strong>" + this.PedidosCli[0].pedFormaPgto + "<br/>";
            dadosPedidos += " <strong>Total Pedido: </strong> R$ " + parseFloat(this.TotalPedido).toFixed(2).toString().replace('.', ',') + "<br/>";

            await axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 3);
                });

            let _dadosEmail = {
                nomeDe: 'Corofergs',
                nomePara: this.Cliente.cliNomeEmpresa,
                assunto: 'Andamento Pedido: ' + this.PedidosCli[0].pedId,
                destinatario: this.Cliente.cliEmail,
                emailResposta: this.logintopo.pfcEmailRecebeAviso,
                textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa).replace('[NumeroPedido]', this.PedidosCli[0].pedId).replace('[Status]', _situacao) + "<br>" + dadosPedidos,
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
            }
            await axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                .then(response => {
                    if (response.data == 1) {
                        console.log('email enviado')
                    }
                });

            let _dadosEmail2 = {
                nomeDe: 'Corofergs',
                nomePara: 'Corofergs',
                assunto: 'Aprovação Pedido: ' + this.PedidosCli[0].pedId,
                destinatario: this.logintopo.pfcEmailRecebeAviso,
                emailResposta: this.logintopo.pfcEmailRecebeAviso,
                textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', 'Corofergs').replace('[NumeroPedido]', this.PedidosCli[0].pedId).replace('[Status]', _situacao) + "<br>" + dadosPedidos,
                nomeAnexo: '',
                anexo: '',
                tipoAnexo: ''
            }
            await axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail2)
                .then(response => {
                    if (response.data == 1) {
                        console.log('email 2 enviado')
                        window.location.reload();
                    }
                });

        },
        validaHora(value) {
            var _hora = value.toString();
            if (_hora.length == 1)
                _hora = "0" + _hora;
            return _hora;
        },
        formatarValor(valor) {
            if (valor != '' && valor != null) {
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }
            else {
                valor = 0;
                return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
            }

        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        calculatotal() {
            var valorAvulsos = 0;
            var valorProdutos = 0;


            if (this.ListaPedidoItem != '') {
                this.ListaPedidoItem.forEach(item => {
                    valorProdutos += item.pdiValorTotalProduto;
                });
            }

            if (this.ListaPedidoItemAdicional != '') {
                this.ListaPedidoItemAdicional.forEach(item => {
                    valorAvulsos += item.piaValorTotalProduto;
                });
            }

            this.TotalPedido = (valorProdutos + valorAvulsos + parseFloat(this.PedidosCli[0].pedValorFrete)) - parseFloat(this.PedidosCli[0].pedValorCupom);
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        AddImagens() {
            if (this.selectedFile != '' && this.selectedFile != null) {
                this.selectedFile.map(async (element) => {
                    const uploadSuccess = await this.UploadImg(element);
                    if (uploadSuccess.status == 200) {
                        this.urlComprovante = uploadSuccess.data.nameFile;

                        await axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                            .then(response => {
                                this.PedidoComprovante = response.data;
                                // console.log('this.PedidoComprovante')
                                // console.log(this.PedidoComprovante)
                                if (this.PedidoComprovante != "") {
                                    let _pedidoComprovante = {
                                        PpcId: this.PedidoComprovante[0].ppcId,
                                        GrtId: this.PedidoComprovante[0].grtId,
                                        CdtId: this.PedidoComprovante[0].cdtId,
                                        IdiId: 1,
                                        PpcUrlImagem: this.urlComprovante,
                                        PedId: this.PedidoComprovante[0].pedId,
                                    }

                                    axios.put(`/Process/PedPedidoPgtoComprova/Update`, _pedidoComprovante)
                                        .then(response => {
                                            if (response.status == 200) {
                                                this.$mensagemAvisoSucesso("Comprovante enviado.")
                                            }
                                        });

                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });

                    } else {
                        this.$mensagemErro("Não foi possível carregar a imagem.");
                        return null;
                    }
                });
            } else {
                this.$mensagemAviso("Selecione o comprovante para enviar.");
            }
        },
    },
    async created() {

        const cookieData = Cookies.get(this.$CookieCliente);
        if (cookieData) {
            const _dadosRecuperados = JSON.parse(cookieData);

            await axios.get('/Process/PedPedidos/GetAllByCliId/' + _dadosRecuperados.usuarioId)
                .then(response => {
                    this.PedidosCli = response.data.filter(x => x.pedId == this.id);
                    console.log('PedidosCli')
                    console.log(this.PedidosCli)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli[0].cliId)
                .then(response => {
                    this.Cliente = response.data;
                    console.log('this.Cliente')
                    console.log(this.Cliente)
                });

            await axios.get('/Process/ConConfigFormasPagamento/GetById/4')
                .then(response => {
                    this.logintopo = response.data;
                    console.log('this.logintopo')
                    console.log(this.logintopo)

                })
                .catch(error => {
                    console.error(error);
                });



            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;
                    console.log('ListaPedidoItem')
                    console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                    // console.log('ListaPedidoItemAdicional')
                    // console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosMensagem = response.data;
                    // console.log('PedidosMensagem')
                    // console.log(this.PedidosMensagem)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoAgendamento/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosAgendamento = response.data;
                    // console.log('PedidosAgendamento')
                    // console.log(this.PedidosAgendamento)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                    // console.log('PedidosEndereco')
                    //  console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

            axios.get(`/Process/CadEstados/GetByPaisId/76`)
                .then(response => {
                    this.Estados = response.data
                    // console.log('this.Estados')
                    //  console.log(this.Estados)
                });

            axios.get(`/Process/CadCidades/GetAll`)
                .then(response => {
                    this.Cidades = response.data
                    // console.log('this.Cidades')
                    // console.log(this.Cidades)
                });

            axios.get(`/Process/CadBairros/GetAll`)
                .then(response => {
                    this.Bairros = response.data
                    //  console.log('this.Bairros')
                    //  console.log(this.Bairros)
                });

            axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidoComprovante = response.data.filter(x => x.ppcUrlImagem != '')
                    //  console.log('this.PedidoComprovante')
                    //  console.log(this.PedidoComprovante)
                });

            axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    console.log('this.Situacoes')
                    console.log(this.Situacoes)
                });

            this.calculatotal();
        }
    },
}
</script>

<style>.das {
    display: block !important;
}

.slider-text h2 p {
    font-size: 60px;
    color: #081624;
    padding: 10px;
    display: inline-block;
    background: #99CC33;
    line-height: 1;
}

span p {
    color: #ffffff !important;
    font-size: 20px;
}

.text-secondary2 {
    color: #5b5b5b !important;
}</style>
