<template>
  <main>
    <div class="slider-area ">
      <div class="single-slider hero-overly slider-height2 d-flex align-items-center"
        v-if="informacoestopo != '' && informacoestopo[0].insInstitucionalImagens != ''"
        :style="{ backgroundImage: 'url(' + $imgURL + informacoestopo[0].insInstitucionalImagens[0].itgUrlImagem + ')', backgroundSize: 'cover', height: '300px' }">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap pt-100">
                <h2 style="text-shadow: rgb(255, 255, 255) 1px 2px;color:#000;">{{ informacoestopo[0].itiNome }}</h2>
                <!--nav aria-label="breadcrumb ">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/" style="color:#000000;">Home</router-link></li>
                    <li class="breadcrumb-item"><a href="#" style="color:#000000;">Informações Gerais</a></li>
                  </ol>
                </!--nav-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content" style="padding-top: 80px;">
      <div class="container">
        <div class="row">
          <aside class="col-lg-3">
            <div class="sidebar">
              <div class="widget widget-cats">
                <h3 class="widget-title" style="text-decoration:underline;">Menu Informações</h3>
                <!-- End .widget-title -->
                <ul v-if="informacoes != ''">
                  <li style="padding-top: 5px;padding-bottom: 5px;" v-for="itens in informacoes" :key="itens.itiId">
                    <a href="#" @click.prevent="carregaTexto(itens.itiId)"> {{ itens.itiNome }}</a>
                  </li>
                </ul>
              </div><!-- End .widget -->
            </div><!-- End .sidebar sidebar-shop -->
          </aside><!-- End .col-lg-3 -->
          <div class="col-lg-9" v-if="informacao != ''">
            <article class="entry single-entry">
              <div class="entry-body">
                <h2 class="entry-title pb-3">
                  {{ informacao[0].itiNome }}
                </h2><!-- End .entry-title -->

                <div class="entry-content editor-content" style="padding-bottom: 60px;">
                  <p v-html="informacao[0].itiResumo1"></p>
                </div><!-- End .entry-content -->

              </div><!-- End .entry-body -->
            </article><!-- End .entry -->
          </div><!-- End .col-lg-9 -->
        </div><!-- End .row -->
      </div><!-- End .container -->
      <div v-if="infocall != ''" class="cta bg-image bg-dark"
        :style="{ backgroundImage: 'url(' + $imgURL + infocall[0].insInstitucionalImagens[0].itgUrlImagem + ')', paddingTop: '80px', paddingBottom: '80px' }">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-10 col-md-8 col-lg-6">
              <div class="cta-text text-center">
                <h3 class="cta-title text-white">{{ infocall[0].itiNome }}</h3><!-- End .cta-title -->
                <p class="cta-desc text-light2" v-html="infocall[0].itiResumo1" style="color: #ccc !important;"></p>
                <!-- End .cta-desc -->

                <a :href="infocall[0].itiLinkBtn1" class="btn btn-primary btn-rounded"
                  target="_blank"><span>{{ infocall[0].itiTextoBtn1 }}</span><i class="icon-long-arrow-right"></i></a>
              </div><!-- End .cta-text -->
            </div><!-- End .col-sm-10 col-md-8 col-lg-6 -->
          </div><!-- End .row -->
        </div><!-- End .container -->
      </div><!-- End .cta -->
    </div><!-- End .page-content -->
  </main>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

export default {
  name: 'InformacoesView',
  setup() {
    const route = useRoute();
    const id = route.params.idInformacoes;
    return {
      id
    };
  },
  components: {
  },
  data: () => ({
    informacoestopo: [],
    informacoes: [],
    informacao: [],
    infocall: [],
  }),
  methods: {
    carregaMetas() {
      useHead({
        title: 'Informações - Corofergs Soluções em Usinagem'
      })
    },
    carregaTexto(value) {
      this.informacao = this.informacoes.filter(x => x.itiId == value)
    },
  },
  created() {

    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/17/informacoesgeraistopo')
      .then(response => {
        this.informacoestopo = response.data;
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/18/informacoes')
      .then(response => {
        this.informacoes = response.data.filter(x => x.itiVisivel).sort((a, b) => a.itiOrdem - b.itiOrdem);

        if (this.id) {
          this.carregaTexto(this.id);
        }
        else {
          this.carregaTexto(this.informacoes[0].itiId);
        }
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/19/callaction')
      .then(response => {
        this.infocall = response.data;
      })
      .catch(error => {
        console.error(error);
      });

  },
}
</script>

<style>
.hhss {
  display: none;
}

.sli p {
  color: #ede3e3 !important;
}</style>
