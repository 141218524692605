<template>
  <div>
    <main class="main">
      <section class="main-carrinho" style="background-color: rgb(206 204 205);">
        <section class="">
          <div class="container">
            <div class="row py-5">
              <div class="col-lg-6 hidden-xs hidden-sm">
                <div class="carrinho-bg set-img text-center d-flex align-items-center justify-content-center"
                  v-if="logintopo != ''">
                  <img v-if="logintopo[0].insInstitucionalImagens != ''" :src="$imgURL + logintopo[0].insInstitucionalImagens[0].itgUrlImagem" style="width: 400px; padding-bottom: 30px;" />
                </div>
              </div>
              <div class="col-lg-5">
                <div class="box_content">
                  <h3 v-if="logintopo != ''" class="titulo-principal text-center mb-5">{{ logintopo[0].itiNome }}</h3>
                  <div class="input-group mb-3">
                    <input v-model="Formulario.Email" type="text" class="form-control form-control-icone"
                      placeholder="Email" onfocus="this.placeholder=''" onblur="this.placeholder='Email'">
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-envelope"></i>
                      </span>
                    </div>
                  </div>
                  <div v-if="AbreSenha || AbreCad" class="input-group mb-3">
                    <input v-model="Formulario.Senha" type="password" class="form-control form-control-icone"
                      :placeholder="AbreCad ? 'Crie uma senha' : AbreSenha ? 'Digite sua senha' : 'Senha'"
                      onfocus="this.placeholder=''" onblur="this.placeholder='Senha'">
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-lock"></i>
                      </span>
                    </div>
                  </div>
                  <div v-if="AbreCad" class="input-group mb-3">
                    <input v-model="Formulario.Nomecli" type="text" class="form-control form-control-icone"
                      placeholder="Nome Completo*" onfocus="this.placeholder=''"
                      onblur="this.placeholder='Nome Completo*'">
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-user-circle"></i>
                      </span>
                    </div>
                  </div>
                  <div v-if="AbreCad" class="input-group mb-3">
                    <input v-model="Formulario.Whatscli" type="text" class="form-control form-control-icone"
                      placeholder="Whatsapp*" onfocus="this.placeholder=''"
                      onblur="this.placeholder='Whatsapp*'">
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-phone"></i>
                      </span>
                    </div>
                  </div>
                  <div v-if="AbreCad" class="form-check mb-3">
                    <input v-model="Formulario.TipoDocumento" class="form-check-input" type="radio" value="CPF"
                      id="cpfOption" name="tipoDocumento" style="margin-top: 7px" />
                    <label class="form-check-label" for="cpfOption" style="padding-left: 5px;">
                      CPF
                    </label>
                  </div>

                  <div v-if="AbreCad" class="form-check mb-3">
                    <input v-model="Formulario.TipoDocumento" class="form-check-input" type="radio" value="CNPJ"
                      id="cnpjOption" name="tipoDocumento" style="margin-top: 7px" />
                    <label class="form-check-label" for="cnpjOption" style="padding-left: 5px;">
                      CNPJ
                    </label>
                  </div>
                  <div v-if="AbreCad" class="input-group mb-3">
                    <input v-model="Formulario.Documento"
                      v-mask="(Formulario.TipoDocumento === 'CPF') ? '###.###.###-##' : '##.###.###/####-##'" type="text"
                      class="form-control form-control-icone"
                      :placeholder="(Formulario.TipoDocumento === 'CPF') ? 'CPF' : 'CNPJ'" @focus="this.placeholder = ''"
                      @blur="(event) => this.placeholder = (Formulario.TipoDocumento === 'CPF') ? 'CPF' : 'CNPJ'" />
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-id-card"></i>
                      </span>
                    </div>
                  </div>
                  <div v-if="AbreCad && Formulario.TipoDocumento == 'CNPJ'" class="input-group mb-3">
                    <input v-model="Formulario.RazaoSocial" type="text" class="form-control form-control-icone"
                      placeholder="Nome Empresa*" onfocus="this.placeholder=''" onblur="this.placeholder='Nome Empresa*'">
                    <div class="input-group-append">
                      <span class="input-group-text input-icone" id="#">
                        <i class="fas fa-user-circle"></i>
                      </span>
                    </div>
                  </div>
                  <a v-if="AbreSenha" href="#" class="navbar_detalhes-link" @click.prevent="dialog = true"
                    style="color:#333333;">
                    Esqueci a senha
                  </a>
                  <!-- <button class="btn btn-success w-100 mt-4">Fazer Login</button> -->
                  <button v-if="!AbreSenha && !AbreCad && logintopo != ''" @click="ValidaCliente()"
                    class="btn w-100 mt-4">{{ logintopo[0].itiTextoBtn1 }}</button>
                  <button v-if="AbreSenha && logintopo != ''" @click="entrar()" class="btn w-100 mt-4">{{
                    logintopo[0].itiTextoBtn1 }}</button>
                  <button v-if="AbreCad && logintopo != ''" @click="carregaCliente()"
                    class="btn w-100 mt-4">{{
                      logintopo[0].itiTextoBtn1 }}</button>

                </div>
              </div>
            </div>
          </div>
          <v-row justify="center">
            <v-dialog v-model="dialog" width="370px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Reset de Senha</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <small>*Digite seu e-mail cadastrado</small>
                        <v-text-field v-model="emailReset" label="E-mail" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black" variant="text" @click="dialog = false">
                    Fechar
                  </v-btn>
                  <v-btn variant="text" @click="EnviarResetSenha()" class="btn btn-success">
                    Enviar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </section>
      </section>
    </main>

  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { SHA1, SHA256 } from 'crypto-js';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useHead } from '@vueuse/head';

export default {
  name: 'LoginView',
  components: {
  },
  data: () => ({
    logintopo: [],
    areaCliente: [],
    ListClientes: [],
    usuario: '',
    senha: '',
    Formulario: {
      Email: '',
      Senha: '',
      Nomecli: '',
      Whatscli: '',
      TipoDocumento: 'CPF',
      Documento: '',
      RazaoSocial: '',
    },
    showModal_senha: false,
    AbreSenha: false,
    AbreCad: false,
    Clientes: [],
    ClientesGeral: [],
    Cliente: [],
    DadosCompra: {
      produtos: [],
      mensagem: [],
      agenda: [],
      foto: [],
      avulsos: [],
      baloes: [],
      informacao: [],
      pagamento: [],
      cupom: [],
    },
    ClientesGeralReset: [],
    dialog: false,
    emailReset: '',
    templateEmail: [],
    senhaGerada: '',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Login - Corofergs Soluções em Usinagem'
			})
		},
    validateCPF(cpf) {
      // Remove quaisquer caracteres não numéricos do CPF
      const cleanedCPF = cpf.replace(/\D/g, '');

      // Verifica se o CPF tem 11 dígitos
      if (cleanedCPF.length !== 11) {
        return false;
      }

      // Validação do CPF
      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(cleanedCPF[i - 1], 10) * (11 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cleanedCPF[9], 10)) {
        return false;
      }

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(cleanedCPF[i - 1], 10) * (12 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(cleanedCPF[10], 10)) {
        return false;
      }

      return true;
    },
    validateCNPJ(cnpj) {
      // Remove quaisquer caracteres não numéricos do CNPJ
      const cleanedCNPJ = cnpj.replace(/\D/g, '');

      if (cleanedCNPJ.length !== 14) {
        return false;
      }

      // Validação do CNPJ
      const calcDigit = (str, weights) => {
        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
          sum += parseInt(str[i]) * weights[i];
        }
        const remainder = sum % 11;
        return remainder < 2 ? 0 : 11 - remainder;
      };

      const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const weights2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

      const dig1 = calcDigit(cleanedCNPJ, weights1);
      const dig2 = calcDigit(cleanedCNPJ + dig1, weights2);

      return (
        parseInt(cleanedCNPJ[12]) === dig1 && parseInt(cleanedCNPJ[13]) === dig2
      );
    },
    validateWhatsApp(whatsapp) {
      // Remove quaisquer caracteres não numéricos
      const cleanedWhatsApp = whatsapp.replace(/\D/g, '');

      // Verifica se o campo contém exatamente 11 dígitos
      if (cleanedWhatsApp.length === 11) {
        return /^\d+$/.test(cleanedWhatsApp);
      }
      
      return false;
    },
    ValidaCliente() {
      const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

     if (this.Formulario.Email == '') {
        this.$mensagemAviso("Por favor preencha o E-mail");
      }
      else if (!this.Formulario.Email.trim().match(emailPattern)) {
        this.$mensagemAviso("E-mail Inválido.");
      }  
      else {
        if (!this.Abresenha) {
          axios.get(`/Process/CliClientes/GetAll`)
            .then(response => {
              this.ClientesGeral = response.data;
              this.Clientes = response.data.filter(x => x.cliEmail.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

              if (this.Clientes != '') {
                this.$mensagemAviso("Usuario ja cadastrado, informe sua senha.");
                this.AbreSenha = true;
                this.AbreCad = false;
                this.logintopo[0].itiNome = "Login";
              }
              else {
                this.$mensagemAviso("Usuario novo, informe alguns dados.");
                this.AbreCad = true;
                this.AbreSenha = false;
                this.logintopo[0].itiNome = "Cadastro";
              }

            });
        }
      }
    },
    carregaCliente() {
      if (this.Formulario.Email == '') {
        this.$mensagemAviso("Por favor preencha o E-mail");
      }
      else if (this.Formulario.Senha == '') {
        this.$mensagemAviso("Por favor preencha o Senha");
      }
      else if (this.Formulario.Nomecli == '') {
        this.$mensagemAviso("Por favor preencha o Nome");
      }
      else if (this.Formulario.Whatscli == '') {
        this.$mensagemAviso("Por favor preencha o Telefone");
      }
      else if (!this.validateWhatsApp(this.Formulario.Whatscli)) {
        this.$mensagemAviso("Whatsapp inválido.");
      }
      else if (this.Formulario.Documento == '') {
        this.$mensagemAviso("Por favor preencha o CPF ou CNPJ");
      }
      else if (this.Formulario.TipoDocumento == 'CNPJ' && this.Formulario.RazaoSocial == '') {
        this.$mensagemAviso("Por favor preencha Nome Empresa");
      }
      else if (this.Formulario.TipoDocumento == 'CPF' && !this.validateCPF(this.Formulario.Documento)){
        this.$mensagemAviso("CPF Inválido.");
      }
      else if (this.Formulario.TipoDocumento == 'CNPJ' && !this.validateCNPJ(this.Formulario.Documento)){
        this.$mensagemAviso("CNPJ Inválido.");
      }
      else {
       // console.log('this.Formulario')
       // console.log(this.Formulario)
        let testacli = this.ClientesGeral.filter(x => x.cliEmail.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

        if (testacli != '') {
          this.$mensagemAviso("Usuario ja cadastrado, informe sua senha.");
          this.AbreSenha = true;
          this.AbreCad = false;
        }
        else {
          let _tipocliente = 1;
          if (this.Formulario.TipoDocumento != 'CPF') {
            _tipocliente = 2;
          }

          let _cliente =
          {
            cliId: 0,
            cliVisivel: true,
            CliClienteTipo: { cctId: _tipocliente },
            cliDataCadastro: moment(),
            cliNomeEmpresa: this.Formulario.Nomecli,
            cliRazaoSocial: this.Formulario.RazaoSocial,
            cliCpf: this.Formulario.TipoDocumento == 'CPF' ? this.Formulario.Documento : "",
            cliCnpj: this.Formulario.TipoDocumento == 'CNPJ' ? this.Formulario.Documento : "",
            cliWhatsapp: this.Formulario.Whatscli,
            CliEmail: this.Formulario.Email,
            cliSenha: SHA256(this.Formulario.Senha).toString(),
            cliAtivo: true,
          };

          axios.post(`/Process/CliClientes/Add`, _cliente)
            .then(response => {
              if (response.data > 0) {

                let codcli = response.data;
                let _dados = {
                  nomeUsuario: '',
                  usuarioId: '',
                  senha: '',
                  email: '',
                }

                _dados.nomeUsuario = this.Formulario.Nomecli;
                _dados.usuarioId = codcli;
                _dados.senha = SHA256(this.Formulario.Senha).toString();
                _dados.email = this.Formulario.Email;

                Cookies.set(this.$CookieCliente, JSON.stringify(_dados), { expires: 2 / 24 });

                this.$router.push("/MensagemPedido");
                this.$store.commit('updateVariavelHeader1', "Olá, " + _dados.nomeUsuario);
              }
            });
        }
      }

    },
    entrar() {
      let testacli = this.ClientesGeral.filter(x => x.cliEmail.toLowerCase().trim() == this.Formulario.Email.toLowerCase().trim());

      if (testacli == '') {
        this.$mensagemAviso("Usuario novo, informe alguns dados.");
        this.AbreCad = true;
        this.AbreSenha = false;
      }
      else {
        if (SHA256(this.Formulario.Senha).toString() == testacli[0].cliSenha) {
          let codcli = testacli[0].cliId;
          let _dados = {
            nomeUsuario: '',
            usuarioId: '',
            senha: '',
            email: '',
          }

          _dados.nomeUsuario = testacli[0].cliNomeEmpresa;
          _dados.usuarioId = codcli;
          _dados.senha = testacli[0].cliSenha;
          _dados.email = this.Formulario.Email;

          Cookies.set(this.$CookieCliente, JSON.stringify(_dados), { expires: 2 / 24 });
          this.$store.commit('updateVariavelHeader1', "Olá, " + _dados.nomeUsuario);


          let dadosArmazenados = localStorage.getItem(this.$DadosCompra);
          if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados.produtos != '') {
           
                this.$router.push('/endereco');
            }
            else {
              this.$router.push('/meuspedidos');
            }
          }
          else {
            this.$router.push('/meuspedidos');
          }
        }
        else {
          this.$mensagemErro("Usuario ou senha inválido.");
        }
      }
    },
    gerarSenha() {
      const comprimentoSenha = 8;
      const caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let senha = "";

      for (let i = 0; i < comprimentoSenha; i++) {
        const indice = Math.floor(Math.random() * caracteres.length);
        senha += caracteres.charAt(indice);
      }

      this.senhaGerada = senha;
    },
    EnviarResetSenha() {
      if (this.emailReset == '') {
        this.$mensagemAviso("Por favor preencha o E-mail Cadastrado");
      }
      else {
        axios.get(`/Process/CadMensagensPadrao/GetAll`)
          .then(response => {
            this.templateEmail = response.data.filter(x => x.mpdId == 2);
            this.gerarSenha();

            var _result = this.Clientes.filter(x => x.cliEmail.toLowerCase().trim() == this.emailReset.toLowerCase().trim());
            if (_result.length > 0) {
              _result[0].cliSenha = SHA256(this.senhaGerada).toString();
              //console.log('_result')
             // console.log(_result)
              axios.put(`/Process/CliClientes/Update`, _result[0])
                .then(response => {
                  if (response.status == 200) {
                    let _dadosEmail = {
                      nomeDe: 'Site Cestas Porto Alegre',
                      nomePara: _result[0].cliNomeEmpresa,
                      assunto: 'Reset de Senha',
                      destinatario: _result[0].cliEmail,
                      emailResposta: 'lopes@zepol.com.br',
                      textoEmail: this.templateEmail[0].mpdTexto.replace('[SenhaReset]', this.senhaGerada),
                      nomeAnexo: '',
                      anexo: '',
                      tipoAnexo: ''
                    }
                   // console.log('_dadosEmail')
                  //  console.log(_dadosEmail)
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                      .then(response => {
                        if (response.data == 1) {
                          this.$mensagemSucesso("Reset de senha enviado! Verifique seu e-mail.");
                          this.resetEmail = "";
                          this.dialog = false;
                        }
                      });
                  }
                  else {
                    this.$mensagemErro("Não foi possível resetar sua senha, contate Empresa.")
                  }
                });
            }
            else {
              this.$mensagemErro("E-mail não cadastrado")
            }
          });
      }
    },
  },
  created() {
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/21/textologin')
      .then(response => {
        this.logintopo = response.data;
        //console.log('this.logintopo')
        //console.log(this.logintopo)
      })
      .catch(error => {
        console.error(error);
      });
  },
}
</script>

<style>
.das {
  display: block !important;
}

.slider-text h2 p {
  font-size: 60px;
  color: #081624;
  padding: 10px;
  display: inline-block;
  background: #99CC33;
  line-height: 1;
}
</style>
